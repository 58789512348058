import api from "./axios.service";

class UserService {
  async getMyData() {
    return await api.get("user");
  }

  async getUsers() {
    return await api.get("users/all");
  }

  async getUser(id) {
    return await api.get(`user/${id}`);
  }

  async editUser(id, email, firstname, lastname, role) {
    return await api.put(`user/edit/${id}`, {
      email,
      account: {
        firstname,
        lastname,
        role,
      },
    });
  }

  async updateMyData(password_or_mail, new_password) {
    const data = !new_password
      ? { email: password_or_mail }
      : { password: password_or_mail, new_password };
    return await api.post("user/update", data);
  }

  async deleteUser(id) {
    return await api.delete(`user/remove/${id}`);
  }
}

export default new UserService();
