import "./NotFound.css";
import { Link } from "react-router-dom";

const NotFound = ({ bg_white, error }) => {
  return (
    <section className="container notfound">
      <Link to="/">
        <div className="logo-origin"></div>
      </Link>
      <div className="notfound">
        <div className="leftbar">
          <h1 className="title-notfound">Uh ohhh !</h1>
          <div className="subtitle">
            {[401, 403, "deny"].indexOf(error) !== -1
              ? "L'accès à la page demandée à été refusée."
              : typeof error === "string" || Array.isArray(error)
              ? error
              : error === 500
              ? "Une erreur inconnue est survenue, veuillez réessayer."
              : "Nous ne trouvons pas la page que vous recherchez :("}
          </div>
          <Link to="/" className="btn">
            Retourner à l'accueil
          </Link>
        </div>
        <div className="img">
          <img
            src="https://user-images.githubusercontent.com/59733143/155220930-23ccf242-1375-407e-a30f-744b14ecbf61.png"
            alt="Not found"
          />
        </div>
      </div>
    </section>
  );
};

export default NotFound;
