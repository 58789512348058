import { useRef } from "react";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import DragIndicator from "../../DragIndicator";

const Items = ({
  input,
  value,
  index,
  setValue,
  reorder,
  moveCard,
  placeholder,
}) => {
  const draggingInput = useRef();

  const removeInput = (name) => {
    setValue(value.filter((a) => a.name !== name));
  };

  const changeValue = (name, replacing) => {
    const newArray = value.map((a) => {
      if (a.name === name) {
        a.value = replacing;
      }
      return a;
    });
    setValue(newArray);
  };

  const addInput = () => {
    const newValue = [...value];
    newValue.push({
      name: value[value.length - 1].name + "1",
      value: "",
    });
    setValue(newValue);
  };

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!draggingInput.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = draggingInput.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const isDraggable = () => {
    return reorder && value.length > 1 ? true : false;
  };

  const opacity = isDragging ? 0 : 1;

  if (isDraggable()) {
    drag(drop(draggingInput));
  }

  return (
    <div
      className={`input-text multiple${isDraggable() ? " draggable" : ""}`}
      style={{ opacity }}
      id={input.name}
      ref={draggingInput}
      data-handler-id={handlerId}
    >
      {value.length > 1 && (
        <div className="move">
          <DragIndicator />
        </div>
      )}
      <input
        type="text"
        name={input.name}
        value={input.value}
        placeholder={placeholder || ""}
        onChange={(e) => changeValue(input.name, e.target.value)}
      />
      {value.length - 1 === index ? (
        <AiFillPlusCircle className="add" onClick={() => addInput()} />
      ) : (
        <AiFillMinusCircle
          className="remove"
          onClick={() => removeInput(input.name)}
        />
      )}
    </div>
  );
};

export default Items;
