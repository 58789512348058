import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import helpers from "../Helpers";

const OrderDragDrop = ({ value, setValue, options, returnValueObject }) => {
  const elements = value.length > 0 ? value : options;
  const [data, setData] = useState(
    elements.map((item, i) => {
      return {
        id: uuid(),
        content: item.answerName,
        answerID: item.answerID,
        position: i,
      };
    })
  );

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#058081" : "#e9e8ee",
    padding: 3,
    borderRadius: 3,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8 * 2,
    margin: `0 0 3px 0`,

    // change background colour if dragging
    background: isDragging ? "rgba(24, 153, 153, 0.9)" : "white",
    color: isDragging ? "white" : "inherit",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [moved] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, moved);
    for (var i = 0; i < result.length; i++) {
      if (result[i].position !== i) {
        //console.log("items need to change", result[i]);
        result[i].position = i;
        //call api here.
      }
    }

    return result;
  };

  const dragEndHandle = (result) => {
    if (!result.destination) {
      return;
    }
    //const { source, destination } = result;
    // console.log("source", source);
    // console.log("destination", destination);
    const items = reorder(data, result.source.index, result.destination.index);
    // items[result.source.index].position = result.destination.index;
    // for (var i = result.source.index + 1; i < items.length; i++) {
    //   items[i].position = i;
    // }
    setData(items);
    setValue(items.map((item) => (returnValueObject ? item : item.answerID)));
  };

  //console.log(data);

  return data.length === 0 ? (
    ""
  ) : (
    <div className="dragdrop order">
      <DragDropContext onDragEnd={(result) => dragEndHandle(result)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {data.map((item, index) => {
                  return (
                    <Draggable
                      draggableId={item.id}
                      key={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className="dnd-item"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <span className="position">
                              {item.position + 1} -
                            </span>
                            {helpers.getEncodedImg(item.content) ? (
                              <img
                                src={helpers.getEncodedImg(item.content)}
                                alt="response"
                                className="img-option"
                              />
                            ) : helpers.isOnlyOneEmoji(item.content) ? (
                              <span className="bigger">{item.content}</span>
                            ) : (
                              item.content
                            )}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default OrderDragDrop;
