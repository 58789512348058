import { useEffect, useState } from "react";
import Helpers from "../Helpers";

const Textarea = ({
  value,
  setValue,
  name,
  placeholder,
  error,
  returnValueObject = false,
}) => {
  const [nameText, setnameText] = useState(name);

  useEffect(() => {
    setnameText(Helpers.generateKey(5, 1));
  }, []);

  return (
    <textarea
      className={`text${error !== false ? " error bounce" : ""}`}
      name={nameText}
      placeholder={placeholder || ""}
      rows="5"
      onChange={(e) => setValue(returnValueObject ? e.target : e.target.value)}
      defaultValue={value}
    ></textarea>
  );
};

export default Textarea;
