import { useEffect, useState } from "react";
import Helpers from "../Helpers";

const InputText = ({
  value,
  setValue,
  name,
  placeholder,
  label,
  type,
  error,
  returnValueObject = false,
}) => {
  const [nameInput, setName] = useState(name);

  useEffect(() => {
    setName(Helpers.generateKey(5, 1));
  }, []);

  return (
    <div className={`input-text${error !== false ? " error bounce" : ""}`}>
      {label && (
        <label htmlFor={nameInput}>
          {label === true ? placeholder : label}
        </label>
      )}
      <input
        type={type !== "text" && type ? type : "text"}
        id={nameInput}
        name={nameInput}
        value={value}
        placeholder={placeholder || ""}
        onChange={(e) =>
          setValue(returnValueObject ? e.target : e.target.value)
        }
      />
    </div>
  );
};

export default InputText;
