import { useLocation, useHistory, Link } from "react-router-dom";
import { IoIosArrowBack, IoIosHelpCircleOutline } from "react-icons/io";
import api from "../services/user.service";
import authApi from "../services/auth.service";
import { Auth } from "../App";
import { useEffect, useState, useContext, useMemo } from "react";
import Loading from "../components/Loading/Basic";
import Header from "../components/Layout/Header";
import NotFound from "./NotFound";
import roles from "../components/Permissions/roles.json";
import {
  getRoles,
  getRole,
  IsAuthorized,
} from "../components/Permissions/roles";
import InputText from "../components/Forms/Input";
import Select from "../components/Forms/Select";
import Modal from "../components/Modal";
import swal from "sweetalert2";

const EditUser = () => {
  const location = useLocation().pathname;
  const history = useHistory();
  const isUserEdit =
    location.split("/")[2] === "new" ? false : location.split("/")[2];
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const authData = useContext(Auth);
  const isModerator = IsAuthorized("moderator");
  const isAdmin = IsAuthorized("admin");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isLoading !== true || !isUserEdit) {
      if (!isUserEdit) {
        setRole(Object.values(roles)[0].name);
      }
      if (!isUserEdit && isLoading) {
        setIsLoading(false);
      }
      return false;
    }

    const fetchData = async () => {
      try {
        const response = await api.getUser(isUserEdit);
        if (response.data) {
          if (!isUserEdit) {
            return history.push("/users/");
          }

          setData(response.data);
          setEmail(response.data.email);
          setFirstname(response.data.account.firstname);
          setLastname(response.data.account.lastname);
          if (response.data.account.role) {
            setRole(getRole(response.data.account.role));
          }
          setIsLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          return setIsLoading(403);
        }
        setIsLoading(500);
      }
    };
    fetchData();

    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  const getErrors = useMemo(
    () =>
      error && (
        <div
          className={`alert ${typeof error === "string" ? "error" : "success"}`}
        >
          {typeof error === "string" ? error : error.message}
        </div>
      ),
    [error]
  );

  const getListRoles = useMemo(() => {
    if (!isAdmin) {
      return getRoles().filter((role) => !role.match(/admin/i));
    }

    return getRoles();
  }, [isAdmin]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    if (!email || !firstname || !lastname) {
      return setError("Tous les champs sont requis");
    }
    if (isAdmin && !role) {
      return setError("Tous les champs sont requis");
    }

    setIsLoading(true);

    try {
      const response = isUserEdit
        ? await api.editUser(isUserEdit, email, firstname, lastname, role)
        : await authApi.register(email, firstname, lastname, role);
      if (response.data) {
        if (!isUserEdit) {
          return history.push("../users/");
        }

        setError({
          success: true,
          message: "Les données ont été mises à jour",
        });
        setIsLoading(false);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 409 &&
        error.response.data.message === "Email already exists"
      ) {
        setIsLoading(false);
        return setError("Cette adresse mail existe déjà.");
      }
      setIsLoading(500);
    }
  };

  const removeUser = async (e) => {
    e.preventDefault();
    const { isConfirmed } = await new swal({
      title: "Etes vous sûr de vouloir supprimer ce membre ?",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    });
    if (!isConfirmed) {
      return false;
    }

    setIsLoading(true);
    try {
      const response = await api.deleteUser(isUserEdit);
      if (response.data) {
        console.log(response);
        return history.push("../users/");
      }
    } catch (error) {
      setError("Une erreur inconnue s'est produite");
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading === 401 || isLoading === 403 || isLoading === 500 ? (
    <NotFound error={isLoading} />
  ) : isLoading && isUserEdit && data.length === 0 ? (
    <Loading />
  ) : (
    <>
      <Header />
      <section className="edit">
        <div className="container">
          <Link to="/users/" className="goback">
            <IoIosArrowBack />
            Retourner à la liste des utilisateurs
          </Link>
          <div className="emphase">
            <div className="name-emphase">
              {isUserEdit
                ? isModerator
                  ? "Consulter un utilisateur"
                  : "Modifier un utilisateur"
                : "Créer un utilisateur"}
            </div>
          </div>
          <div className="questions">
            {(isModerator && !role && isUserEdit) ||
            isUserEdit === authData.user.id ? (
              <fieldset className="account">
                <div className="row">
                  <div className="col">
                    <div className="label">Prénom</div>
                    <div className="value">{data.account.firstname}</div>
                  </div>
                  <div className="col">
                    <div className="label">Nom</div>
                    <div className="value">{data.account.lastname}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Email</label>
                    <div className="value">
                      <a href={`mailto:${data.email}`}>{data.email}</a>
                    </div>
                  </div>
                  <div className="col">
                    <div className="label">Membre verifié</div>
                    <div className="value">
                      {data.account?.verify?.isVerified === true
                        ? "Oui"
                        : "Non"}
                    </div>
                  </div>
                </div>
                {data.auth && typeof data.auth === "string" && (
                  <div className="data">
                    <label>LastLogin</label>
                    <div className="value">
                      {new Date(data.auth).toLocaleDateString()}
                    </div>
                  </div>
                )}
                {data.auth && data.auth.lastLogin && (
                  <div className="data">
                    <label>LastLogin</label>
                    <div className="value">
                      {new Date(data.auth.lastLogin).toLocaleDateString()}
                    </div>
                  </div>
                )}
              </fieldset>
            ) : (
              <form onSubmit={handleSubmit}>
                <fieldset className="account">
                  {getErrors}

                  <div className="row">
                    <InputText
                      type="text"
                      value={firstname}
                      placeholder="Prénom"
                      setValue={setFirstname}
                      label={true}
                    />
                    <InputText
                      type="text"
                      value={lastname}
                      placeholder="Nom de famille"
                      setValue={setLastname}
                      label={true}
                    />
                  </div>
                  <InputText
                    type="email"
                    value={email}
                    placeholder="Adresse mail"
                    setValue={setEmail}
                    label={true}
                  />

                  <div className="row">
                    <div className="left">
                      <label htmlFor="role">Rôle</label>
                      <Select
                        value={role}
                        setValue={setRole}
                        placeholder="Indiquez le rôle"
                        options={getListRoles}
                      />
                    </div>
                    <button
                      className="icon"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsModalOpen(true);
                      }}
                    >
                      <IoIosHelpCircleOutline />
                    </button>
                  </div>

                  <div className="data">
                    <div className="label">Membre verifié</div>
                    <div className="value">
                      {data.account?.verify?.isVerified === true
                        ? "Oui"
                        : "Non"}
                    </div>
                  </div>

                  {data.auth &&
                    typeof data.auth === "object" &&
                    Object.entries(data.auth).map((auth) => {
                      return (
                        <div key={auth[0]} className="data">
                          <label>{auth[0]}</label>
                          <div className="value">
                            {auth[1].match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T/)
                              ? new Date(auth[1]).toLocaleDateString()
                              : auth[1]}
                          </div>
                        </div>
                      );
                    })}
                  {data.auth && typeof data.auth === "string" && (
                    <div className="data">
                      <label>LastLogin</label>
                      <div className="value">
                        {new Date(data.auth).toLocaleDateString()}
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <button
                      className="submit load"
                      type="submit"
                      disabled={isLoading}
                    >
                      Enregistrer
                    </button>
                    {isAdmin && isUserEdit && (
                      <button
                        className="submit load"
                        disabled={isLoading}
                        onClick={removeUser}
                      >
                        Supprimer le membre
                      </button>
                    )}
                  </div>
                </fieldset>
              </form>
            )}
          </div>
        </div>
      </section>
      <Modal
        isModalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        title="Information"
        content={
          <div className="roles-container">
            {Object.values(roles).map((role) => {
              if (!isAdmin && role.name.match(/admin/i)) {
                return "";
              }
              return (
                <div className="role" key={role.name}>
                  <strong>{role.name}</strong>
                  <p>{role.description}</p>
                </div>
              );
            })}
          </div>
        }
      />
    </>
  );
};

export default EditUser;
