import api from "../services/user.service";
import { useEffect, useState } from "react";
import Loading from "../components/Loading/Basic";
import Header from "../components/Layout/Header";
import NotFound from "./NotFound";
import InputText from "../components/Forms/Input";
import { CgProfile, CgLock } from "react-icons/cg";
import { useRef } from "react";
import { useMemo } from "react";

export const Account = () => {
  const pwdIndicator = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const tabs = [
    {
      name: "Profil",
      icon: <CgProfile />,
    },
    {
      name: "Mot de passe",
      icon: <CgLock />,
    },
  ];
  const [currentTab, setCurrentTab] = useState("Profil");
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(["", "", ""]);

  useEffect(() => {
    if (isLoading !== true) {
      return false;
    }

    const fetchData = async () => {
      try {
        const response = await api.getMyData();
        if (response) {
          setData(response.data);
          setEmail(response.data.email);
          setIsLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          return setIsLoading(403);
        }
        setIsLoading(500);
      }
    };
    fetchData();

    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!pwdIndicator.current) {
      return false;
    }
    const len = password[1].length,
      value = password[1];

    if (len === 0) {
      pwdIndicator.current.className = "safe-pwd blank";
    } else if (len >= 1 && len <= 3) {
      pwdIndicator.current.className = "safe-pwd risky";
    } else if (
      len >= 4 &&
      value.match(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/) &&
      !value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/)
    ) {
      pwdIndicator.current.className = "safe-pwd weak";
    } else if (
      len >= 8 &&
      value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/)
    ) {
      pwdIndicator.current.className = "safe-pwd strong";
    }
  }, [password, pwdIndicator]);

  const btn = useMemo(
    () => (
      <div className="row">
        <button className="submit load" type="submit" disabled={isLoading}>
          Mettre à jour
        </button>
      </div>
    ),
    [isLoading]
  );

  const getErrors = useMemo(
    () =>
      error && (
        <div
          className={`alert ${typeof error === "string" ? "error" : "success"}`}
        >
          {typeof error === "string" ? error : error.message}
        </div>
      ),
    [error]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);

    if (currentTab === "Profil") {
      if (!email) {
        return setError("L'email est requis");
      }
      setIsLoading(true);

      try {
        const response = await api.updateMyData(email);
        if (response.data.success) {
          setError({
            success: true,
            message: "Votre adresse mail a été mise à jour",
          });
        }
      } catch (error) {
        setError("Une erreur s'est produite, veuillez réessayer");

        if (
          error.response &&
          error.response.status === 409 &&
          error.response.data.message === "This mail already exists"
        ) {
          setError("Erreur : cette adresse mail est déjà utilisée.");
        }
      } finally {
        setIsLoading(false);
      }
    } else if (currentTab === "Mot de passe") {
      if (password.indexOf("") !== -1) {
        return setError("Tous les champs doivent êtres complétés");
      }
      if (
        pwdIndicator.current &&
        !pwdIndicator.current.className.match(/weak|strong/)
      ) {
        return setError("Le mot de passe n'est pas assez sécurisé");
      }
      if (password[1] !== password[2]) {
        return setError("Les mots de passes doivent être identiques");
      }

      setPassword(password.map((p) => ""));
      setIsLoading(true);

      try {
        const response = await api.updateMyData(password[0], password[1]);
        if (response.data.success) {
          setError({
            success: true,
            message: "Votre mot de passe a été mis à jour",
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          return setError("Votre mot de passe est incorrect");
        }
        setError("Une erreur s'est produite, veuillez réessayer");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return isLoading === 401 || isLoading === 403 || isLoading === 500 ? (
    <NotFound error={isLoading} />
  ) : isLoading && data.length === 0 ? (
    <Loading />
  ) : (
    <>
      <Header />
      <section className="account">
        <div className="container">
          <div className="emphase">
            <div className="name-emphase">Mon compte</div>
          </div>
          <div className="questions">
            <form onSubmit={handleSubmit}>
              <ul className="tabs">
                {tabs.map((tab) => (
                  <li
                    className={`tab${tab.name === currentTab ? " active" : ""}`}
                    key={tab.name}
                    onClick={() => {
                      setError(false);
                      setCurrentTab(tab.name);
                    }}
                  >
                    {tab.icon} {tab.name}
                  </li>
                ))}
              </ul>
              {currentTab === "Profil" ? (
                <fieldset>
                  <h1>Profil</h1>
                  {getErrors}
                  <div className="row">
                    <div className="col">
                      <div className="label">Prénom</div>
                      <div className="value">{data.account.firstname}</div>
                    </div>
                    <div className="col">
                      <div className="label">Nom</div>
                      <div className="value">{data.account.lastname}</div>
                    </div>
                  </div>
                  <InputText
                    type="email"
                    value={email}
                    placeholder="Adresse mail"
                    setValue={setEmail}
                    label={true}
                  />
                  {btn}
                </fieldset>
              ) : (
                <fieldset>
                  <h1>Mot de passe</h1>
                  {getErrors}
                  <InputText
                    type="password"
                    value={password[0]}
                    placeholder="Mot de passe actuel"
                    setValue={(v) =>
                      setPassword(password.map((p, i) => (i === 0 ? v : p)))
                    }
                    label={true}
                  />
                  <InputText
                    type="password"
                    value={password[1]}
                    placeholder="Nouveau mot de passe"
                    setValue={(v) =>
                      setPassword(password.map((p, i) => (i === 1 ? v : p)))
                    }
                    label={true}
                  />
                  <div className="pwd-indicator">
                    <span className="text">Niveau de sécurité</span>
                    <div className="safe-pwd blank" ref={pwdIndicator}></div>
                  </div>

                  <InputText
                    type="password"
                    value={password[2]}
                    placeholder="Confirmer le nouveau mot de passe"
                    setValue={(v) =>
                      setPassword(password.map((p, i) => (i === 2 ? v : p)))
                    }
                    label={true}
                  />
                  {btn}
                </fieldset>
              )}
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
