import "./Login.css";
import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Input from "../../components/EditQuestions/Input";
import { Auth } from "../../App";
import authApi from "../../services/auth.service";
import Helpers from "../../components/Helpers";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [page, setPage] = useState("login"); // login|register|forgot
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const authData = useContext(Auth);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(window.location.search);
      const action = location.pathname.match(/reset/)
        ? "password"
        : "activation";
      const email = params.get("email");
      const secure_token = params.get("secure_token");
      if (email && secure_token) {
        const sendLostRequest = async () => {
          try {
            const post = await authApi.confirmResetPassword(
              email,
              secure_token
            );
            if (post.data.success) {
              authData.flashMsg.setLoginFlashMsg({
                success: true,
                message:
                  "Votre nouveau mot passe a été regénéré, veuillez consulter votre boite mail et ensuite vous connecter",
              });
              return history.push("/");
            } else {
              setErrorMessage("Une erreur inconnue s'est produite");
            }
          } catch (error) {
            authData.flashMsg.setLoginFlashMsg(
              "Aucune demande de réinitisalition de mot de passe a été identifiée, elle a peut-être expiré ou le lien n'est pas valide."
            );
            return history.push("/");
          }
        };
        const sendActivationRequest = async () => {
          try {
            await authApi.confirmActivation(email, secure_token);
            authData.flashMsg.setLoginFlashMsg({
              success: true,
              message:
                "Votre compte est désormais activé, vous pouvez maintenant vous connecter",
            });
            return history.push("/");
          } catch (error) {
            authData.flashMsg.setLoginFlashMsg(
              "Le lien que vous avez suivi est incorrect, il a peut-être expiré ou le lien n'est pas valide."
            );
            return history.push("/");
          }
        };
        setIsLoading(true);
        if (action === "password") {
          sendLostRequest();
        } else {
          sendActivationRequest();
        }
        setIsLoading(false);
      }
      return;
    }

    const flashMsg = authData.flashMsg.getLoginFlashMsg();
    if (flashMsg) {
      setErrorMessage(flashMsg);
      authData.setSession(false);
      return;
    }

    // eslint-disable-next-line
  }, [location]);

  const changePage = (switchpage) => {
    if (!["login", "register", "forgot"].includes(switchpage)) {
      throw new Error("Page not found");
    }
    if (page === "register") {
      setFirstname("");
      setLastname("");
    }
    setEmail("");
    setPassword("");
    setErrorMessage(false);
    setPage(switchpage);
  };

  const resendActivationLink = async () => {
    setIsLoading(true);
    try {
      await authApi.resendActivation(email);
      setErrorMessage({
        success: true,
        message:
          "Le mail d'activation vient d'être renvoyé, veuillez vérifiez vos mails",
      });
    } catch (error) {
      let msg = "Une erreur inconnue s'est produite";
      if (error?.response?.status === 429) {
        msg =
          "Une demande similaire a déjà été réalisée, veuillez réessayer dans " +
          error.response.data.access +
          " minutes.";
      }
      setErrorMessage(msg);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(false);
    const emptyMsg = "Tous les champs doivent êtres complétés";

    if (page === "forgot") {
      if (!email) {
        return setErrorMessage("L'email doit être complété");
      }
      setIsLoading(true);
      let msg = {
        success: true,
        message:
          "Si une adresse mail existe dans notre système, un mail vous sera envoyé pour réinitialiser votre mot de passe",
      };

      try {
        await authApi.delay(3000);
        await authApi.resetPassword(email);
        return setErrorMessage(msg);
      } catch (error) {
        msg = "Une erreur s'est produite, veuillez réessayer";
        if (error?.response?.status === 429) {
          msg =
            "Une demande similaire a déjà été réalisée, veuillez réessayer dans " +
            error.response.data.access +
            " minutes.";
        }

        return setErrorMessage(msg);
      } finally {
        setEmail("");
        setIsLoading(false);
      }
    }

    if (page === "register") {
      if (
        !firstname ||
        !lastname ||
        !email ||
        !password ||
        !passwordConfirmation
      ) {
        return setErrorMessage(emptyMsg);
      } else if (!Helpers.isValidMail(email)) {
        return setErrorMessage("Email non valide");
      } else if (!Helpers.isStrongPassword(password)) {
        return setErrorMessage("Le mot de passe n'est pas assez fort");
      } else if (password !== passwordConfirmation) {
        return setErrorMessage("Les mots de passes doivent être identiques");
      }
      setIsLoading(true);

      try {
        const register = await authApi.register(email, firstname, lastname);
        if (register) {
          setErrorMessage({
            success: true,
            message:
              "Votre inscription a bien été prise en compte, veuillez consulter vos mails et valider votre compte pour continuer",
          });
        }
      } catch (error) {
        if (error.response.status === 409) {
          return setErrorMessage("Cette adresse mail existe déjà");
        }
        setErrorMessage("Une erreur inconnue s'est produite");
      } finally {
        setIsLoading(false);
      }
      return;
    }

    if (!email || !password) {
      return setErrorMessage(emptyMsg);
    } else if (!Helpers.isValidMail(email)) {
      return setErrorMessage("Email non valide");
    }
    setIsLoading(true);
    let msg = "Erreur : Votre mail ou votre mot de passe est incorrect";

    try {
      const authTempting = await authApi.login(email, password);
      if (!authTempting) {
        setIsLoading(false);
        return setErrorMessage(msg);
      }
      authData.setSession(authTempting.data);
      return history.push("/");
    } catch (error) {
      if (error?.response?.status === 403) {
        msg = [
          "Votre compte n'est pas activé, cette action est requise pour continuer. ",
          <br key="1" />,
          <a
            href="/"
            key="2"
            onClick={(e) => {
              e.preventDefault();
              resendActivationLink();
            }}
          >
            Renvoyer le mail d'activation
          </a>,
        ];
      }
      setIsLoading(false);
      return setErrorMessage(msg);
    }
  };

  return (
    <section className="login-container">
      <div className="container">
        <div className="emphase">
          <div className="icon-emphase"></div>
        </div>
        <form
          className={`login${page === "register" ? " register" : ""}`}
          onSubmit={handleSubmit}
        >
          <div className="subtitle">
            {page === "register"
              ? "Enregistrez-vous pour créer votre compte"
              : page === "login"
              ? "Connectez-vous pour accéder à votre compte"
              : "Redéfinissez votre nouveau mot de passe"}
          </div>

          {errorMessage &&
            typeof errorMessage === "object" &&
            errorMessage.message && (
              <div
                className={`alert alert-sep ${
                  errorMessage.error ? " error" : " success"
                }`}
              >
                <i className="icon"></i>
                <div>{errorMessage.message}</div>
              </div>
            )}
          {errorMessage &&
            (typeof errorMessage === "string" ||
              Array.isArray(errorMessage)) && (
              <div className="alert alert-sep error">
                <i className="icon"></i>
                <div>{errorMessage}</div>
              </div>
            )}

          {page === "register" && (
            <>
              <Input
                value={firstname}
                placeholder="Prénom"
                onChange={setFirstname}
              />
              <Input
                value={lastname}
                placeholder="Nom de famille"
                onChange={setLastname}
              />
            </>
          )}

          <Input
            type="email"
            value={email}
            placeholder="Adresse email"
            onChange={setEmail}
          />
          {page !== "forgot" && (
            <Input
              type="password"
              value={password}
              placeholder="Mot de passe"
              onChange={setPassword}
            />
          )}
          {page === "register" && (
            <Input
              type="password"
              value={passwordConfirmation}
              placeholder="Confirmez votre mot de passe"
              onChange={setPasswordConfirmation}
            />
          )}

          <button type="submit" className="submit load" disabled={isLoading}>
            {page === "login"
              ? "Connexion"
              : page === "register"
              ? "S'enregistrer"
              : "Réinitialiser"}
          </button>

          {page === "login" ? (
            <div className="links">
              <a
                href="/"
                className="forgot"
                onClick={(e) => {
                  e.preventDefault();
                  changePage("forgot");
                }}
              >
                Mot de passe oublié ?
              </a>
              <a
                href="/"
                className="register"
                onClick={(e) => {
                  e.preventDefault();
                  changePage("register");
                }}
              >
                Créer un compte
              </a>
            </div>
          ) : (
            <a
              href="/"
              className="connect"
              onClick={(e) => {
                e.preventDefault();
                changePage("login");
              }}
            >
              {page === "register"
                ? "Vous avez déjà un compte ?"
                : "Me connecter"}
            </a>
          )}
        </form>
      </div>
    </section>
  );
};

export default Login;
