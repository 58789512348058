import { IsAuthorized, AreRolesAuthorized, DenyAccess } from "./roles";

export const Watcher = ({ children, extended = true, conditional = false }) => {
  const allowedRoles = ["watcher", "moderator", "admin"];

  try {
    if (!extended && !IsAuthorized(allowedRoles[0])) {
      return DenyAccess(conditional);
    } else if (extended && !AreRolesAuthorized(allowedRoles)) {
      return DenyAccess(conditional);
    }
  } catch (error) {
    return DenyAccess(conditional);
  }

  return <>{children}</>;
};
