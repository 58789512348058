import {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import helpers from "../components/Helpers";
import {
  IoIosArrowBack,
  IoIosAddCircleOutline,
  IoIosSave,
  IoIosEye,
  IoMdTrash,
} from "react-icons/io";
import ContentEditable from "../components/ContentEditable";
import Switch from "react-switch";
import QuestionCreate from "../components/QuestionCreate";
import toast, { Toaster } from "react-hot-toast";
import { Auth } from "../App";
import api from "../services/forms.service";
import Header from "../components/Layout/Header";
import Loading from "../components/Loading/Basic";
import NotFound from "./NotFound";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import React from "react";
import { Pie, Bar, HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-labels";
import swal from "sweetalert2";

const CreateForm = () => {
  const location = useLocation().pathname;
  const isPageEdit =
    location.split("/")[2] === "create" ? false : location.split("/")[3];
  const history = useHistory();
  const [isModified, setIsModified] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [owner, setOwner] = useState({});
  const [formName, setFormName] = useState("");
  const [formDesc, setFormDesc] = useState("");
  // eslint-disable-next-line
  const [themeQuestionEnabled, setThemeQuestionEnabled] = useState(false);
  const [answerAllowed, setAnswerAllowed] = useState(true);
  const [responses, setResponses] = useState([]);
  const [questions, setQuestions] = useState([helpers.newQuestionStructure(0)]);
  const [options, setOptions] = useState({
    collectMailAddress: true,
    requireLogin: false,
    confirmationMessage: null,
    sendMailWhenResponses: false,
    allowOnlyOneResponse: false,
  });
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authData = useContext(Auth);
  const [isAnswersViewing, setIsAnswersViewing] = useState(false);
  const [sideBarContent, setSideBarContent] = useState("responses"); // responses|options
  const sidebar = useRef();

  useEffect(() => {
    const preventExit = (ev) => {
      ev.preventDefault();
      return (ev.returnValue = "Are you sure you want to close?");
    };
    window.addEventListener("beforeunload", preventExit);

    if (!isModified) {
      window.removeEventListener("beforeunload", preventExit);
      return false;
    }

    return () => {
      window.removeEventListener("beforeunload", preventExit);
    };
  }, [isModified]);

  useEffect(() => {
    if (!isPageEdit) {
      return false;
    }

    const fetchData = async () => {
      try {
        const { data } = await api.getForm(isPageEdit);
        if (data) {
          setFormName(data.form.name);
          setFormDesc(data.form.description);
          setAnswerAllowed(data.form.answerAccepted);
          setQuestions(data.form.questions);
          setOwner({ ...data.form.owner, createdAt: data.form.createdAt });
          setResponses(data.responses);
          setIsPageLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          [401, 403, 404].indexOf(error.response.status) === -1
        ) {
          return setIsPageLoading(error.response.status);
        }
        return setIsPageLoading(500);
      }
    };
    fetchData();
  }, [authData, location, isPageEdit]);

  useEffect(() => {
    if (isAnswersViewing && document.body.className !== "sidebar-open") {
      document.body.className = "sidebar-open";
    }

    const handleClickOutside = (event) => {
      if (sidebar.current && !sidebar.current.contains(event.target)) {
        document.body.removeAttribute("class");
        setIsAnswersViewing(false);
      } else {
        document.body.className = "sidebar-open";
      }
    };

    const handlePressEscape = (event) => {
      if (event.key === "Escape") {
        document.body.removeAttribute("class");
        setIsAnswersViewing(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keydown", handlePressEscape, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handlePressEscape, true);
      document.body.removeAttribute("class");
    };
  }, [isAnswersViewing, setIsAnswersViewing]);

  const updateField = (value, field, initial) => {
    if (value === initial) {
      return false;
    }
    setIsModified(true);
    return field(value);
  };

  const isValidQuestionToEscape = () => {
    if (questions.length === 0) {
      return true;
    }

    let result = false;
    const questionsValidated = questions.map((question) =>
      question.props?.options
        ? {
            ...question,
            props: {
              ...question.props,
              options: question.props.options.map((o) =>
                o.answerName === ""
                  ? {
                      ...o,
                      answerName: "Option sans titre",
                    }
                  : o
              ),
            },
          }
        : question
    );

    setQuestions(questionsValidated);

    try {
      if (
        isEditing &&
        isEditing.id &&
        questionsValidated.find((q) => q.questionId === isEditing.id)
      ) {
        result = helpers.isValidQuestion(
          questionsValidated.find((q) => q.questionId === isEditing.id)
        );
      } else {
        result = questionsValidated.map((q) => helpers.isValidQuestion(q));
      }
    } catch (error) {
      toast.error(error.message);
      return false;
    }

    if (Array.isArray(result)) {
      result = result.includes(false) ? false : true;
    }

    if (result === false) {
      alert("This question must be valid");
    }
    return result;
  };

  const addQuestion = () => {
    if (!isValidQuestionToEscape()) {
      return false;
    }
    setIsModified(true);
    const id =
      questions.reduce((maxId, item) => Math.max(maxId, item.questionId), 0) +
      1;

    setQuestions([...questions, helpers.newQuestionStructure(id)]);
    setIsEditing({
      id,
      previousData: helpers.newQuestionStructure(id),
    });
  };

  const removeForm = async () => {
    if (!isPageEdit) {
      throw new Error("Remove question is only available for editing");
    }
    setIsEditing(false);
    setIsPageLoading(true);

    try {
      const { data } = await api.deleteForm(isPageEdit);
      if (data && data.success) {
        return history.push("/");
      }
    } catch (error) {
      return setIsPageLoading(500);
    }
  };

  const submitForm = async () => {
    if (!isValidQuestionToEscape()) {
      return false;
    }
    setIsEditing(false);
    if (!formName || !questions) {
      return toast.error(
        "Le nom du formulaire ou les questions doivent êtres complétées pour poursuivre"
      );
    }
    setIsSubmitting(true);
    const loader = toast.loading("Mise à jour en cours...");

    try {
      const params = {
        name: formName,
        description: formDesc,
        answerAccepted: answerAllowed,
        questions,
      };

      if (!isPageEdit) {
        await api.createForm(params);
        toast.dismiss(loader);
        return history.push("/");
      } else {
        await api.updateForm(isPageEdit, params);
        toast.dismiss(loader);
        toast.success("Les données ont été mises à jour");
        setIsModified(false);
      }
    } catch (error) {
      const msg =
        "Une erreur inconnue s'est produite, merci de réessayer ultérieurement";
      return toast.error(
        error.response.data.message ? error.response.data.message : msg
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const dragEndHandle = (result) => {
    if (!result.destination) {
      return;
    }

    setIsModified(true);
    const newPostion = result.destination.index;
    const prevPosition = result.source.index;
    setQuestions(
      questions.map((question, index) =>
        index === newPostion
          ? questions[prevPosition]
          : index === prevPosition
          ? questions[newPostion]
          : question
      )
    );
  };

  const isAdminEditing = useMemo(() => {
    return (
      owner.account &&
      owner._id !== authData.user.id &&
      !authData.user.account.role.match(/user/i)
    );
  }, [authData, owner]);

  const getAnswers = useCallback(
    (id) => {
      let answers = [];
      for (let i = 0; i < responses.length; i++) {
        for (let r = 0; r < responses[i].responses.length; r++) {
          const answer = responses[i].responses[r];
          if (Number(answer.questionId) === id) {
            answers.push(responses[i].responses[r]);
          }
        }
      }
      return answers;
    },
    [responses]
  );

  const getColors = (nb, opacity = 0.3) => {
    const random = () => {
      const o = Math.round,
        r = Math.random,
        s = 255;
      return (
        "rgba(" +
        o(r() * s) +
        "," +
        o(r() * s) +
        "," +
        o(r() * s) +
        "," +
        opacity +
        ")"
      );
    };

    const colors = [
      "rgba(54, 162, 235, 1)",
      "rgba(255, 159, 64, 1)",
      "rgba(255, 99, 132, 1)",
      "rgba(255, 300, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 99, 71, 1)",
    ];
    const result = {};

    if (typeof nb === "object" && nb.light && Array.isArray(nb.light)) {
      result.light = nb.light;
    } else if (typeof nb === "number") {
      if (nb > colors.length) {
        nb = nb - colors.length;
        for (let i = 0; i < nb; i++) {
          colors.push(random());
        }
        result.light = colors;
      } else {
        result.light = colors.filter((c, i) => i + 1 <= nb);
      }
    }

    result.light = result.light.map((r) =>
      r.replace(
        /(rgba\(\d{0,3}, ?\d{0,3}, ?\d{0,3}, ?)0\.\d\)/,
        "$1" + opacity + ")"
      )
    );
    result.black = result.light.map((r) =>
      r.replace(/(rgba\(\d{0,3}, ?\d{0,3}, ?\d{0,3}, ?)0\.\d\)/, "$11)")
    );
    return result;
  };

  const hasResponses = (question, optionId) => {
    if (typeof question === "string" || typeof question === "number") {
      question = questions.find((q) => q.questionId === Number(question));
      if (!question) {
        throw new Error("Question not found");
      }
    }

    if (!question.questionId) {
      throw new Error("Incorrect question data");
    }

    return responses.find((a) =>
      a.responses.find((r) => r.questionId === question.questionId)
    );
  };

  const getDataAnswers = (question, answers, getImages = false) => {
    if (!question.props.options) {
      return [];
    }

    return question.props.options.map((option) => {
      if (getImages) {
        return {
          [helpers.getEncodedImg(option.answerName) ? "src" : "text"]:
            helpers.getEncodedImg(option.answerName)
              ? helpers.getEncodedImg(option.answerName)
              : option.answerName,
          width: 50,
          height: 50,
        };
      }

      return answers.reduce((acc, a) => {
        if (!isNaN(a.value)) {
          a.value = Number(a.value);
        }
        return a.value === option.answerID ? acc + 1 : acc;
      }, 0);
    });
  };

  const removeAnswers = async (questionId, optionId) => {
    if (!hasResponses(questionId)) {
      return false;
    }

    // TODO Remove Answers func
    // Create the backend to remove either questionId and/or optionId

    alert("under construction");
    console.log(responses);
  };

  const isOptionsHasImg = (question) => {
    return question.props.options.find((option) =>
      helpers.getEncodedImg(option.answerName)
    )
      ? true
      : false;
  };

  return typeof isPageLoading === "number" || isPageLoading === 500 ? (
    <NotFound error={isPageLoading} />
  ) : isPageEdit && isPageLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <section className="create-form">
        <div className="container">
          <Link
            to={!owner || owner._id !== authData.user.id ? "/forms/all" : "/"}
            className="goback"
            onClick={(e) => {
              if (isModified) {
                e.preventDefault();
                new swal({
                  title: "Etes vous sûr de vouloir quitter sans sauvegarder ?",
                  text: "Vous perdrez toutes vos données en quittant cette page",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Oui",
                  cancelButtonText: "Non, je reste",
                }).then(function (result) {
                  if (result.isConfirmed) {
                    history.push(
                      !owner || owner._id !== authData.user.id
                        ? "/forms/all"
                        : "/"
                    );
                  }
                });
              }
            }}
          >
            <IoIosArrowBack />
            {!owner || owner._id !== authData.user.id
              ? "Retourner à la liste des formulaires"
              : "Retourner à mes formulaires"}
          </Link>
          <div className="emphase">
            {isAdminEditing && (
              <div className="alert full info">
                <i className="icon"></i>
                Ce formulaire a été créé par
                <strong>{` ${owner.account.firstname} ${owner.account.lastname} le `}</strong>
                <strong>
                  {new Date(owner.createdAt).toLocaleDateString()}
                </strong>
              </div>
            )}

            <ContentEditable
              className="name-emphase"
              onUpdate={(v) => updateField(v, setFormName, formName)}
              placeholder="Formulaire sans titre"
              setPlaceholderDefault={true}
            >
              {formName}
            </ContentEditable>
            <ContentEditable
              className="subtitle-emphase"
              onUpdate={(v) => updateField(v, setFormDesc, formDesc)}
              placeholder="Description du formulaire"
            >
              {formDesc}
            </ContentEditable>

            <div className="accept_responses">
              <label>
                <span className="text-label">Accepter les réponses</span>
                <Switch
                  onChange={(v) => {
                    setIsModified(true);
                    setAnswerAllowed(v);
                  }}
                  checked={answerAllowed}
                  className="react-switch"
                />
              </label>
              {responses.length > 0 && (
                <div
                  className="responses badge badge-green link"
                  title={`Il y a eu ${responses.length} réponse${
                    responses.length > 1 ? "s" : ""
                  }}`}
                  onClick={() => setIsAnswersViewing(true)}
                >
                  {responses.length}
                </div>
              )}
            </div>

            {!answerAllowed && (
              <div className="alert full warning">
                <i className="icon"></i>
                <strong>Attention :</strong> Les réponses ne sont pas acceptées,
                ce formulaire est donc désactivé
              </div>
            )}
          </div>
          <DragDropContext
            onDragStart={() => setIsEditing(false)}
            onDragEnd={(result) => dragEndHandle(result)}
          >
            <Droppable droppableId="droppable-questions">
              {(provided) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {questions.map((question, index) => {
                      return (
                        <QuestionCreate
                          data={question}
                          index={index}
                          setData={setQuestions}
                          isEditing={isEditing}
                          questions={questions}
                          setIsEditing={setIsEditing}
                          setIsModified={setIsModified}
                          themeQuestionEnabled={themeQuestionEnabled}
                          isFormValid={isValidQuestionToEscape}
                          key={`question-${question.questionId}`}
                          notification={toast}
                          hasResponses={hasResponses}
                          removeResponses={removeAnswers}
                          idForm={isPageEdit || "new"}
                        />
                      );
                    })}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>

          <div className="row">
            <button className="submit" onClick={addQuestion}>
              <IoIosAddCircleOutline />
              Ajouter une question
            </button>
          </div>
          <div className="row">
            {isPageEdit && answerAllowed && !isEditing && (
              <Link
                className="submit"
                to={`/form/${isPageEdit}`}
                target="_blank"
                rel="noreferrer"
              >
                <IoIosEye />
                Visualiser
              </Link>
            )}

            {isModified && (
              <button
                className="submit"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                <IoIosSave />
                Sauvegarder
              </button>
            )}
            {isPageEdit && (
              <button
                className="submit"
                onClick={async () => {
                  const { isConfirmed } = await new swal({
                    title: "Voulez-vous vraiment supprimer le formulaire ?",
                    text: "Vous perdrez toutes vos données en confirmant",
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                  });
                  if (isConfirmed) {
                    removeForm();
                  }
                }}
              >
                <IoMdTrash />
                Supprimer
              </button>
            )}
          </div>
        </div>
        <Toaster position="top-center" />
        {responses.length > 0 && (
          <div
            className={`sidebar-container${isAnswersViewing ? " show" : ""}`}
          >
            <div className="sidebar" ref={sidebar}>
              <div className="sidebar-content">
                <div className="buttons">
                  <div
                    className="close"
                    onClick={() => setIsAnswersViewing(false)}
                    aria-label="Fermer"
                  ></div>
                </div>
                <div className="bubble">
                  <div className="hello">Résumé des réponses</div>
                  <div className="center">
                    {responses.length}
                    {responses.length > 1
                      ? " réponses obtenues"
                      : " réponse obtenue"}
                  </div>
                </div>
                {questions.map((question) => {
                  const answers = getAnswers(question.questionId);
                  let colors,
                    labels = [];

                  if (!question.open) {
                    let size = question.props.min;
                    if (question.type === "Range") {
                      for (
                        let i = question.props.min;
                        i <= question.props.max;
                        i += question.props.step
                      ) {
                        size++;
                        labels.push(i);
                      }
                    } else {
                      size = question.props.options.length;
                    }
                    colors = getColors(size);
                  }

                  return (
                    <div className="bubble" key={question.questionId}>
                      <div className="question question-name">
                        {`${question.question}${
                          question.question.match(/ \? {0,}$/) ? "" : " :"
                        }`}
                      </div>
                      {answers.length > 0 ? (
                        <>
                          <div className="responses">
                            {answers.length} réponse{answers.length > 1 && "s"}
                          </div>
                          <div className="answers">
                            {question.open ? (
                              answers.map((a) => {
                                return (
                                  <div className="answer" key={a._id}>
                                    {a.value}
                                  </div>
                                );
                              })
                            ) : question.type !== "Range" ? (
                              <div className="chart">
                                {answers.length > 12 ? (
                                  <HorizontalBar
                                    data={{
                                      labels: question.props.options.map((o) =>
                                        helpers.getEncodedImg(o.answerName)
                                          ? `Image ${o.answerID + 1}`
                                          : o.answerName
                                      ),
                                      datasets: [
                                        {
                                          data: getDataAnswers(
                                            question,
                                            answers
                                          ),
                                          backgroundColor: colors.light,
                                          borderColor: colors.black,
                                          borderWidth: 1,
                                        },
                                      ],
                                    }}
                                    options={{
                                      plugins: {
                                        labels: {
                                          render: isOptionsHasImg(question)
                                            ? "image"
                                            : "label",
                                          images: getDataAnswers(
                                            question,
                                            answers,
                                            true
                                          ),
                                        },
                                      },
                                    }}
                                  />
                                ) : (
                                  <Pie
                                    data={{
                                      labels: question.props.options.map((o) =>
                                        helpers.getEncodedImg(o.answerName)
                                          ? `Image ${o.answerID + 1}`
                                          : o.answerName
                                      ),
                                      datasets: [
                                        {
                                          data: getDataAnswers(
                                            question,
                                            answers
                                          ),
                                          backgroundColor: colors.light,
                                          borderColor: colors.black,
                                          borderWidth: 1,
                                        },
                                      ],
                                    }}
                                    options={{
                                      plugins: {
                                        labels: {
                                          render: isOptionsHasImg(question)
                                            ? "image"
                                            : "label",
                                          images: getDataAnswers(
                                            question,
                                            answers,
                                            true
                                          ),
                                        },
                                      },
                                    }}
                                  />
                                )}
                              </div>
                            ) : (
                              <Bar
                                data={{
                                  labels,
                                  datasets: [
                                    {
                                      label: question.question,
                                      data: answers.map((a) => Number(a.value)),
                                      backgroundColor: colors.light,
                                      borderColor: colors.black,
                                      borderWidth: 1,
                                    },
                                  ],
                                }}
                                options={{
                                  plugins: {
                                    labels: {
                                      render: "value",
                                    },
                                  },
                                  indexAxis: "y",
                                }}
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="noresponse">
                          Aucune réponse a été enregistrée pour cette quesiton
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </section>
      {isModified && (
        <div
          className="form-not-saved"
          title="Sauvegarder"
          onClick={submitForm}
        >
          <IoIosSave />
        </div>
      )}
    </>
  );
};

export default CreateForm;
