import { useContext } from "react";
import { useHistory } from "react-router";
import { Auth } from "../../App";
import NotFound from "../../routes/NotFound";
import roles from "./roles.json";

// Advanced : https://stackoverflow.com/questions/42567575/hide-some-react-component-children-depending-on-user-role/53052074#53052074
// Demo: https://codesandbox.io/s/znmxlw59jm?file=/src/components/examples/RoleBased/Demo.jsx

export const IsLevel = (role) => {
  return false;
};

export const getRoles = () => {
  return Object.keys(roles).map((role) => roles[role].name);
};

export const getRole = (role, full = false) => {
  role = role.split(".")[1];
  if (Object.keys(roles).indexOf(role) === -1) {
    throw new Error(`This role (${role}) does not exist`);
  }
  return full ? roles[role] : roles[role].name;
};

export const IsAuthorized = (...role) => {
  const history = useHistory();
  const authData = useContext(Auth);

  if (!authData.user) {
    return history.push("/");
  }

  const currentRole = getRole(authData.user.account.role, true);

  const isAllowed = (role) => {
    if (
      role.toLowerCase() !== currentRole.name.toLowerCase() &&
      currentRole.alias &&
      currentRole.alias
        .split(",")
        .find((alias) => role.toLowerCase() === alias.toLowerCase())
    ) {
      return true;
    }

    return role.toLowerCase() === currentRole.name.toLowerCase() ? true : false;
  };

  const isOneOfPermittedRolesIsAllowed = role
    .map((r) => isAllowed(r))
    .find((r) => r === true);

  return isOneOfPermittedRolesIsAllowed ? true : false;
};

export const DenyAccess = (returnNull = false) => {
  if (returnNull) {
    return false;
  }
  return <NotFound />;
};

export const AreRolesAuthorized = (restrictedRoles) => {
  return restrictedRoles.map((role) => IsAuthorized(role)).includes(true);
};
