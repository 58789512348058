import axios from "axios";
import TokenService from "./token.service";

// https://www.bezkoder.com/redux-refresh-token-axios/
// https://github.com/bezkoder/react-jwt-refresh-token

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3030",
});

api.interceptors.request.use(
  (config) => {
    const token = TokenService.getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const authData = TokenService.getData();
    if (!authData) {
      return Promise.reject(error);
    }

    if (
      !error.config.url.match(/refresh-token/) &&
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "Invalid token" &&
      authData
    ) {
      console.log([error.response, authData], "Detect if token has been send");

      try {
        const response = await api.post(
          `user/refresh-token/`,
          {
            id_user: authData.id,
            refresh_token: authData.refreshToken,
          },
          {
            headers: {
              Authorization: "Bearer " + authData.token,
            },
          }
        );

        if (response.data) {
          TokenService.setUser(response.data);
          return window.location.reload();
        }
      } catch (respError) {
        console.log(respError);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
