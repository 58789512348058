const ContentEditable = ({
  children,
  className,
  onUpdate,
  placeholder,
  setPlaceholderDefault,
  ignoreLineBreak = true,
}) => {
  const dataPlaceholder = { "data-placeholder": placeholder };

  const setCaret = (e) => {
    const range = document.createRange();
    range.selectNodeContents(e.currentTarget);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  };

  const handleBlur = (e) => {
    if (
      setPlaceholderDefault &&
      (e.currentTarget.textContent === "" ||
        !e.currentTarget.textContent.match(/\w/))
    ) {
      e.currentTarget.textContent = placeholder;
    }
    return onUpdate ? onUpdate(e.currentTarget.textContent) : false;
  };

  return (
    <div
      className={className}
      contentEditable={true}
      onKeyPress={(e) => {
        if (!ignoreLineBreak) {
          return false;
        }

        if (e.key === "Enter" || e.code === "Enter") {
          e.preventDefault();
        }
      }}
      onBlur={handleBlur}
      onFocus={setCaret}
      suppressContentEditableWarning={true}
      {...dataPlaceholder}
    >
      {children}
    </div>
  );
};

export default ContentEditable;
