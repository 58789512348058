import { Link, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { BsCheckCircle } from "react-icons/bs";
import NotFound from "./NotFound";
import Range from "../components/Forms/Range";
import Select from "../components/Forms/Select";
import InputText from "../components/Forms/Input";
import MultipleInput from "../components/Forms/MultipleInput";
import Tags from "../components/Forms/Tags";
import Textarea from "../components/Forms/Textarea";
import OrderDragDrop from "../components/Forms/Order";
import api from "../services/forms.service";
import LoadingContentLoader from "../components/Loading/contentLoader";
import toast, { Toaster } from "react-hot-toast";
import Helpers from "../components/Helpers";
import auth from "../services/auth.service";
import Header from "../components/Layout/Header";

const Form = () => {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.getFormAsGuest(slug);
        if (data) {
          if (data.form.questions.find((q) => !Helpers.isValidQuestion(q))) {
            return setIsLoading(500);
          }

          setData({
            ...data.form,
            questions: data.form.questions.map((question) => {
              return {
                ...question,
                value:
                  typeof question.defaultValue !== "undefined"
                    ? question.defaultValue
                    : "",
              };
            }),
          });
          setIsLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 404 || error.response.status === 403)
        ) {
          return setIsLoading(
            error.response.status === 403
              ? [
                  <div key={0}>Ce formulaire n'accepte plus de réponses.</div>,
                  <div key={1}>
                    Essayez de contacter le créateur du formulaire si vous
                    pensez qu'il s'agit d'une erreur.
                  </div>,
                ]
              : 404
          );
        }
        setIsLoading(500);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const changeValue = (id, newValue) => {
    setData({
      ...data,
      questions: data.questions.map((q) =>
        q.questionId === id
          ? {
              ...q,
              value: newValue,
              error: newValue || newValue === 0 ? false : true,
            }
          : q
      ),
    });
  };

  const handleSumbit = async (e) => {
    e.preventDefault();
    if (data.questions.find((q) => q.required && q.value === false)) {
      setData({
        ...data,
        questions: data.questions.map((q) =>
          !q.value && q.required ? { ...q, error: true } : q
        ),
      });
      return toast.error(
        "Merci de compléter les champs requis du formulaire pour continuer"
      );
    }

    try {
      setIsSubmitting(true);
      await api.completeForm(
        slug,
        data.questions.map((q) => ({
          questionId: q.questionId,
          value: q.value,
        }))
      );
      setIsLoading(200);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return toast.error(
          "Vous ne pouvez pas compléter votre propre formulaire"
        );
      }
      toast.error("Une erreur s'est produite lors de l'envoi");
    } finally {
      setIsSubmitting(false);
    }
  };

  const isMyForm = useMemo(() => {
    return auth.isConnected() && data.owner === auth.getCurrentUser().id
      ? true
      : false;
  }, [data]);

  return isLoading === true ? (
    <LoadingContentLoader />
  ) : isLoading === 404 ||
    isLoading === 500 ||
    typeof isLoading === "object" ? (
    <NotFound error={isLoading} />
  ) : (
    <>
      {auth.isConnected && <Header />}
      <section className="fill-form">
        <div className="container">
          <div className="emphase">
            <div className="name-emphase">{data.name}</div>
            {data.description && (
              <div className="subtitle-emphase">{data.description}</div>
            )}
          </div>
          {isMyForm && (
            <div className="alert full info">
              <i className="icon"></i> Vous êtes le créateur de ce formulaire,
              vos invités sont les seuls à être habilités à remplir ce
              questionnaire.
            </div>
          )}
          {isLoading === 200 ? (
            <div className="questions success">
              <BsCheckCircle className="icon" />
              <div className="title">
                Merci pour votre participation, vos réponses ont été
                enregistrées !
              </div>
              <Link to="/">Créer mon formulaire</Link>
            </div>
          ) : (
            <form className="questions" action="post" onSubmit={handleSumbit}>
              <ol>
                {data.questions.map((question, i) => {
                  return (
                    <li key={i}>
                      <div className="content-question">
                        {data.options?.presentation?.displayTheme && (
                          <div className="category-question">
                            {question.title}
                          </div>
                        )}
                        <div className="description-question">
                          {question.question}
                          {question.required && (
                            <span className="required">*</span>
                          )}
                        </div>
                        {question.type === "Range" && (
                          <Range
                            value={question.value || ""}
                            setValue={(v) =>
                              changeValue(question.questionId, v)
                            }
                            min={question.props.min}
                            max={question.props.max}
                            step={question.props.step}
                          />
                        )}
                        {question.type === "Select" && (
                          <Select
                            value={question.value}
                            setValue={(v) =>
                              changeValue(question.questionId, v.answerID)
                            }
                            options={question.props.options}
                            placeholder={
                              question.props.placeholder ||
                              "Sélectionnez la réponse"
                            }
                            error={question.error || false}
                            returnValueObject={true}
                          />
                        )}
                        {question.type === "Input" && (
                          <InputText
                            value={question.value || ""}
                            setValue={(v) =>
                              changeValue(question.questionId, v)
                            }
                            placeholder={
                              question.props.placeholder || "Votre réponse"
                            }
                            error={question.error || false}
                            returnValueObject={true}
                          />
                        )}
                        {question.type === "MultipleInput" && (
                          <MultipleInput
                            value={question.value || ""}
                            setValue={(v) =>
                              changeValue(question.questionId, v)
                            }
                            placeholder={
                              question.props.placeholder || "Votre réponse"
                            }
                            returnValueObject={true}
                          />
                        )}
                        {question.type === "Textarea" && (
                          <Textarea
                            value={question.value || ""}
                            setValue={(v) =>
                              changeValue(question.questionId, v)
                            }
                            placeholder={
                              question.props?.placeholder || "Votre réponse"
                            }
                            error={question.error || false}
                            returnValueObject={true}
                          />
                        )}
                        {question.type === "Tags" && (
                          <Tags
                            value={question.value}
                            setValue={(v) =>
                              changeValue(question.questionId, v)
                            }
                            grid={question.props.displayAsGrid}
                            options={question.props.options}
                            multiple={question.props?.multiple || false}
                            error={question.error || false}
                            returnValueObject={true}
                          />
                        )}
                        {question.type === "Order" && (
                          <OrderDragDrop
                            value={question.value}
                            setValue={(v) =>
                              changeValue(question.questionId, v)
                            }
                            options={question.props.options}
                            returnValueObject={true}
                          />
                        )}
                      </div>
                    </li>
                  );
                })}
              </ol>
              <div className="row">
                <button type="submit" disabled={isSubmitting || isMyForm}>
                  <IoIosCheckmarkCircleOutline />
                  Envoyer
                </button>
              </div>
            </form>
          )}

          <Link to="/" className="logo">
            <div className="logo-origin"></div>
          </Link>
          <div className="disclaimer">
            <div>Ce contenu n'est ni créé ni approuvé par Revine Forms.</div>
            <div>
              Si vous pensez que ce contenu est frauduleux, merci de le signaler
              à{" "}
              <a
                href={`mailto:abuse@forms.revine.fr?subject=Contenu frauduleux détecté sur Revine Forms&body=Bonjour,%0D%0A%0D%0AJ'ai détecté un formulaire suspicieux :%0D%0A${window.location.href}`}
              >
                abuse@forms.revine.fr
              </a>
              .
            </div>
          </div>
        </div>
        <Toaster position="bottom-right" />
      </section>
    </>
  );
};

export default Form;
