import "./Loading.css";

const Loading = () => {
  return (
    <section className="loading">
      <div className="container">
        <div>Chargement des données...</div>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </section>
  );
};

export default Loading;
