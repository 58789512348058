import format from "./question.structure";

const Helpers = {
  isValidMail: (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  isStrongPassword: (password) => {
    return password.match(
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
    );
  },
  hasEmoji: (string) => {
    return string.match(
      /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gmu
    );
  },
  getType: (type) => {
    return type
      .toString()
      .match(/(\w+)\(\)/)[1]
      .toLowerCase();
  },
  generateKey: (length, complexity = false) => {
    let result = "";
    let characters = "abcdefghijklmnopqrstuvwxyz";
    const maj = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    if (complexity >= 2) {
      characters += maj;
    } else if (complexity === 3) {
      characters += "0123456789";
    }

    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  isValidType: (type) => {
    return Object.keys(format.defaultTypes).indexOf(type) !== -1 ? true : false;
  },
  setBasicValue: (data) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === "String") {
        data[key] = "";
      } else if (typeof data[key] === "object") {
      } else if (data[key] === "Array") {
        data[key] = [];
      } else if (data[key] === "Object") {
        data[key] = {};
      } else if (data[key] === "Boolean") {
        data[key] = false;
      } else {
        data[key] = null;
      }
    });
    return data;
  },
  loadDefaultProps: (type, loadAll = false) => {
    if (!Helpers.isValidType(type)) {
      throw new Error("This type does not exist");
    }
    const props = Object.keys(format.defaultTypes[type]).filter(
      (p) => !p.match(/^_/)
    );

    if (loadAll) {
      if (loadAll === "new") {
        let data = props.reduce(
          (acc, prop) => ({
            ...acc,
            [prop]:
              prop === "options"
                ? format.defaultOption
                : format.defaultTypes[type][prop],
          }),
          {}
        );
        data = Helpers.setBasicValue(data);

        if (type === "Range") {
          data.min = 0;
          data.max = 10;
          data.step = 1;
        }

        return data;
      }

      return props.reduce(
        (acc, prop) => ({ ...acc, [prop]: format.defaultTypes[type][prop] }),
        {}
      );
    }

    return props;
  },
  loadOpenFromType: (type) => {
    if (!Helpers.isValidType(type)) {
      throw new Error("This type does not exist");
    }
    return format.defaultTypes[type]["_open"];
  },
  loadAllTypes: () => {
    return Object.keys(format.defaultTypes);
  },
  isQuestionCanBeRequired: (type) => {
    if (!Helpers.isValidType(type)) {
      throw new Error("This type does not exist");
    }
    return format.defaultTypes[type]["_disable_required"] ? false : true;
  },
  getTypeTranslation: (type, lang = "fr") => {
    if (!Helpers.isValidType(type)) {
      throw new Error("This type does not exist");
    }
    if (!format.defaultTypes[type]["_translate"][lang]) {
      throw new Error("Lang not found for this type");
    }

    return format.defaultTypes[type]["_translate"][lang];
  },
  getTypeIcon: (type) => {
    if (!Helpers.isValidType(type)) {
      throw new Error("This type does not exist");
    }
    if (!format.defaultTypes[type]["_icon"]) {
      return false;
    }

    return format.defaultTypes[type]["_icon"];
  },
  isOptionImageAllowed: (type) => {
    if (!Helpers.isValidType(type)) {
      throw new Error("This type does not exist");
    }
    return format.defaultTypes[type]["_image_allowed"] ? true : false;
  },
  isValidQuestion: (question) => {
    if (!question && typeof question !== "object") {
      throw new Error("Question not selected");
    }

    if (
      Object.keys(format.defaultQuestionFormat).find(
        (field) => !Object.keys(question).includes(field)
      ) ||
      Object.keys(format.defaultQuestionFormat)
        .map((field) =>
          format.defaultQuestionFormat[field].toLowerCase() ===
          typeof question[field]
            ? true
            : false
        )
        .includes(false)
    ) {
      throw new Error("Required field missing or incorrect field data");
    }

    if (
      Object.keys(format.defaultQuestionFormat).find(
        (field) =>
          format.defaultQuestionFormat[field].toLowerCase() === "string" &&
          question[field] === ""
      )
    ) {
      throw new Error("Field is empty");
    }

    if (!Helpers.isValidType(question.type)) {
      throw new Error("This type does not exist");
    }

    if (
      Helpers.loadDefaultProps(question.type).find(
        (field) => !Object.keys(question.props).includes(field)
      )
    ) {
      throw new Error("Required prop missing");
    }

    if (
      Helpers.loadDefaultProps(question.type)
        .map((prop) =>
          Helpers.loadDefaultProps(question.type, true)[prop].toLowerCase() ===
          typeof question.props[prop]
            ? true
            : Helpers.loadDefaultProps(question.type, true)[prop] === "Array" &&
              typeof question.props[prop] === "object"
            ? true
            : false
        )
        .includes(false)
    ) {
      throw new Error("Validation props not completed");
    }

    if (
      question.props.options &&
      question.props.options.find((option) => option.answerName === "")
    ) {
      throw new Error("Option field must be completed");
    }

    if (
      question.type === "Range" &&
      (question.props.min >= question.props.max ||
        question.props.step >= question.props.max)
    ) {
      throw new Error("Range field data is not correct");
    }

    return true;
  },
  newQuestion: (
    title,
    question,
    type,
    id,
    defaultValue = "",
    required = false
  ) => {
    if (!Helpers.isValidType(type)) {
      throw new Error("This type does not exist");
    }

    const result = {
      title,
      question,
      questionId: id,
      type,
      open: Helpers.loadOpenFromType(type),
      defaultValue,
      required,
      props: Helpers.loadDefaultProps(type, "new"),
    };
    return result;
  },
  newQuestionStructure: (id) => {
    return Helpers.newQuestion(
      "Thème sans titre",
      "Question sans titre",
      "Tags",
      id,
      false,
      false,
      true
    );
    /*{
      title: "Thème sans titre",
      questionId: id,
      question: "Question sans titre",
      type: "Select",
      open: false,
      required: false,
      props: {
        options: [
          {
            answerID: 0,
            answerName: "Option n°1",
          },
        ],
      },
    };
    */
  },
  handleCommonsErrors: async (error) => {
    return false;
  },
  getEncodedImg: (data) => {
    if (!data.match(/^\{"/)) {
      return false;
    }

    try {
      return JSON.parse(data).secure_url;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  popupImg: (e) => {
    console.log(e.target.attributes.src.textContent);
  },
  isValidImage: (string) => {
    return string.match(/(jpeg|jpg|gif|png)$/);
  },
  isImageUrl: (string) => {
    return string.match(/^http.*\.(jpeg|jpg|gif|png)$/);
  },
  isOnlyOneEmoji: (string) => {
    const regexExp =
      /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])$/i;
    return regexExp.test(string);
  },
  isJSON: (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
};

export default Helpers;
