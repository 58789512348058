import "./assets/css/app.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./routes/Home";
import Form from "./routes/Form";
import NotFound from "./routes/NotFound";
import CreateForm from "./routes/CreateForm";
import { useState, createContext, useCallback } from "react";
import Login from "./routes/Login";
import token from "./services/token.service";
import { Account } from "./routes/Account";
import { Watcher } from "./components/Permissions/Watcher";
import { Moderator } from "./components/Permissions/Moderator";
import AllForms from "./routes/AllForms";
import Users from "./routes/Users";
import EditUser from "./routes/EditUser";

export const Auth = createContext();

// https://nicepage.com/html-templates/preview/solutions-improve-productivity-283136?device=desktop

function App() {
  const [userAuth, setUserAuth] = useState(token.getData());

  const setSession = useCallback(async (authData) => {
    if (!authData || authData === "destroy") {
      setUserAuth(false);
      return token.removeUser();
    }
    token.setUser(authData);
    setUserAuth(authData);
  }, []);

  const isLogged = () => {
    return userAuth ? true : false;
  };

  return (
    <Router>
      <Auth.Provider
        value={{
          token: userAuth.token,
          setSession,
          user: userAuth,
          flashMsg: {
            service: token,
            getLoginFlashMsg: token.getLoginFlashMsg,
            setLoginFlashMsg: token.setLoginFlashMsg,
          },
        }}
      >
        <Switch>
          <Route exact path="/form/:slug">
            <Form />
          </Route>
          <Route exact path="/account">
            {isLogged() ? <Account /> : <Login />}
          </Route>
          <Route exact path="/forms/edit/:slug">
            {isLogged() ? <CreateForm /> : <Login />}
          </Route>
          <Route exact path="/users/">
            <Moderator>
              <Users />
            </Moderator>
          </Route>
          <Route exact path="/user/:id">
            <Moderator>
              <EditUser />
            </Moderator>
          </Route>
          <Route exact path="/user/new">
            <Moderator>
              <EditUser />
            </Moderator>
          </Route>
          <Route exact path="/forms/all">
            <Watcher>
              <AllForms />
            </Watcher>
          </Route>
          <Route exact path="/">
            {isLogged() ? <Home /> : <Login />}
          </Route>
          <Route exact path="/confirm-reset-pwd">
            {isLogged() ? <Home /> : <Login />}
          </Route>
          <Route exact path="/confirm-activation/">
            {isLogged() ? <Home /> : <Login />}
          </Route>
          <Route exact path="/login">
            {isLogged() ? <Home /> : <Login />}
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Auth.Provider>
    </Router>
  );
}

export default App;
