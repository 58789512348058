import { useEffect, useRef } from "react";
import "./Modal.css";

const Modal = ({
  isModalOpen,
  setModalOpen,
  title,
  content,
  footer,
  options,
}) => {
  const modal = useRef();

  useEffect(() => {
    if (isModalOpen && document.body.className !== "modal-open") {
      document.body.className = "modal-open";
    }

    const handleClickOutside = (event) => {
      if (modal.current && !modal.current.contains(event.target)) {
        document.body.removeAttribute("class");
        setModalOpen(false);
      } else {
        document.body.className = "modal-open";
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.body.removeAttribute("class");
    };
  }, [isModalOpen, setModalOpen]);

  return (
    <div className={`modal${isModalOpen ? " show" : ""}`} id="modal">
      <div
        className="modal-dialog modal-dialog-centered fade-scale"
        role="document"
        ref={modal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setModalOpen(false)}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body center">{content}</div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    </div>
  );
};

export default Modal;
