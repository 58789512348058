import "./QuestionCreate.css";
import {
  IoIosRadioButtonOff,
  IoIosCheckmarkCircleOutline,
  IoIosTrash,
  IoIosUndo,
  IoIosImage,
} from "react-icons/io";
import ContentEditable from "../ContentEditable";
import Input from "../EditQuestions/Input";
import helpers from "../Helpers";
import Select from "../Forms/Select";
import XIcon from "../XIcon";
import Switch from "react-switch";
import { Draggable } from "react-beautiful-dnd";
import DragIndicator from "../DragIndicator";
import { useState, useMemo } from "react";
import Modal from "../Modal";
import { useDropzone } from "react-dropzone";
import api from "../../services/forms.service";
import swal from "sweetalert2";

const QuestionCreate = ({
  data,
  setData,
  index,
  questions,
  isEditing,
  setIsEditing,
  setIsModified,
  isFormValid,
  themeQuestionEnabled,
  notification,
  hasResponses,
  removeResponses,
  idForm,
}) => {
  const maxFiles = 1;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);

  const displayTranslatedType = (search) => {
    if (search) {
      return helpers.getTypeTranslation(search)
        ? helpers.getTypeTranslation(search)
        : false;
    }

    return helpers.loadAllTypes().map((type) => {
      return {
        value: type,
        label: helpers.getTypeTranslation(type),
        icon: helpers.getTypeIcon(type),
      };
    });
  };

  const updateQuestion = (value, field) => {
    if (typeof data[field] === "undefined") {
      throw new Error(`The field : ${field} of the question does not exist`);
    } else if (data[field] === value) {
      return false;
    }
    setIsModified(true);
    setData(
      questions.map((question) =>
        question.questionId === data.questionId
          ? {
              ...question,
              [field]: value,
              open:
                field === "type"
                  ? helpers.loadOpenFromType(value, "new")
                  : question.open,
              props:
                field === "type"
                  ? helpers.loadDefaultProps(value, "new")
                  : question.props,
            }
          : question
      )
    );
  };

  const addAnswer = () => {
    setIsModified(true);
    setData(
      questions.map((question) =>
        question.questionId === data.questionId
          ? {
              ...question,
              props: {
                ...question.props,
                options: [
                  ...question.props.options,
                  {
                    answerID: Number(data.props.options.length),
                    answerName:
                      "Option n°" + Number(data.props.options.length + 1),
                  },
                ],
              },
            }
          : question
      )
    );
  };

  const editAnswerName = (value, id) => {
    setIsModified(true);
    setData(
      questions.map((question) =>
        question.questionId === data.questionId
          ? {
              ...question,
              props: {
                ...question.props,
                options: question.props.options.map((o) =>
                  id === o.answerID
                    ? {
                        ...o,
                        answerName: value,
                      }
                    : o
                ),
              },
            }
          : question
      )
    );
  };

  const removeAnswer = async (id) => {
    if (typeof id === "undefined") {
      throw new Error("ID not found, unable to remove answer");
    }

    if (hasResponses(data.questionId, id)) {
      const { isConfirmed } = await new swal({
        title: "Voulez-vous supprimer cette réponse ?",
        text: "En supprimant cette réponse, les réponses seront aussi supprimées.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Oui, je supprime",
        cancelButtonText: "Non, j'annule",
      });
      if (!isConfirmed) {
        return false;
      }
      await removeResponses(data.questionId, id);
    }

    setIsModified(true);
    setData(
      questions.map((question) =>
        question.questionId === data.questionId
          ? {
              ...question,
              props: {
                ...question.props,
                options: question.props.options.filter(
                  (o) => id !== o.answerID
                ),
              },
            }
          : question
      )
    );
  };

  const removeQuestion = async (id) => {
    const { isConfirmed } = await new swal({
      title: "Voulez-vous supprimer cette question ?",
      text: hasResponses(id)
        ? "Les réponses associées seront elles aussi supprimées"
        : false,
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Oui, je supprime",
      cancelButtonText: "Non, j'annule",
    });
    if (!isConfirmed) {
      return false;
    }

    if (hasResponses(id)) {
      await removeResponses(id);
    }

    setIsModified(true);
    setData(questions.filter((q) => q.questionId !== data.questionId));
  };

  const updateQuestionProp = (value, prop) => {
    if (typeof data.props[prop] === "undefined") {
      throw new Error(`This prop ${prop} does not exist`);
    }
    setIsModified(true);

    if (data.type === "Range" && prop === "max" && data.props.step > value) {
      const paliers = getPalier.filter((n) => n < value);
      return setData(
        questions.map((question) =>
          question.questionId === data.questionId
            ? {
                ...question,
                props: {
                  ...question.props,
                  step: paliers[paliers.length - 1],
                  [prop]: value,
                },
              }
            : question
        )
      );
    }

    setData(
      questions.map((question) =>
        question.questionId === data.questionId
          ? {
              ...question,
              props: {
                ...question.props,
                [prop]: value,
              },
            }
          : question
      )
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: (file) => {
      if (!file || file.length === 0) {
        return notification.error("Uniquement les images sont acceptées");
      }
      if (!helpers.isValidImage(file[0].name)) {
        return notification.error("Format de l'image non accepté");
      }

      const reader = new FileReader();
      reader.onload = () => {
        setFiles([
          ...files,
          {
            file: file[0],
            preview: reader.result,
          },
        ]);
      };
      reader.readAsDataURL(file[0]);
    },
  });

  const handleSubmitUpload = async () => {
    if (files.length < maxFiles) {
      throw new Error("Max files reached");
    }

    setIsUploading(true);

    try {
      const response = await api.addImage(
        idForm,
        isModalOpen.questionId,
        isModalOpen.answerID,
        files
      );

      if (response.data.result === "success" && response.data.img.secure_url) {
        const img = await fetch(response.data.img.secure_url, {
          method: "HEAD",
        });
        if (img.ok) {
          editAnswerName(
            JSON.stringify(response.data.img),
            isModalOpen.answerID
          );
          setFiles([]);
          setIsModalOpen(false);
        } else {
          notification.error("L'upload a échoué, veuillez réessayer");
        }
      }
    } catch (error) {
      console.log(error);
      notification.error(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const generateModalContent = useMemo(() => {
    return (
      <section>
        <fieldset
          className="drop"
          style={{ display: files.length < maxFiles ? "inherit" : "none" }}
        >
          <div {...getRootProps()} className="drag">
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Glisse les images ici ...</p>
            ) : (
              <>
                <p>
                  Glisse les images ici, ou clique pour sélectionner les images
                </p>
                <em>(Uniquement les images seront acceptées)</em>
              </>
            )}
          </div>
        </fieldset>
        <aside
          className="files-preview"
          style={{ display: files.length > 0 ? "block" : "none" }}
        >
          <div className="text-preview">Aperçu :</div>
          <div className="images">
            {files.map((file, i) => {
              return (
                <div className="img" key={i}>
                  <img src={file.preview} key={i} alt={i} />
                  <div
                    className="remove"
                    onClick={() => {
                      setFiles(files.filter((f) => f.preview !== file.preview));
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </div>
                </div>
              );
            })}
          </div>
        </aside>
        <button
          className={`submit${isUploading ? " load" : ""}`}
          disabled={files.length === 0 || isUploading ? true : false}
          onClick={handleSubmitUpload}
        >
          Uploader
        </button>
      </section>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, isUploading]);

  const getPalier = useMemo(() => {
    if (!data.props.max) {
      return [1, 2, 5, 10, 20, 50];
    }
    return [1, 2, 5, 10, 20, 50].filter((n) => n < data.props.max);
  }, [data.props]);

  console.log(questions);

  return (
    <Draggable
      draggableId={`question-${data.questionId}`}
      key={`question-${data.questionId}`}
      index={index}
      isDragDisabled={questions.length === 1 || isEditing ? true : false}
    >
      {(provided) => {
        return (
          <div
            className="dnd-item"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {questions.length > 1 && !isEditing && (
              <div className="move-question">
                <DragIndicator />
              </div>
            )}
            <div
              className={`questions${
                isEditing.id === data.questionId ? " is-editing" : ""
              }`}
              key={data.questionId}
              onClick={() => {
                if (
                  isEditing &&
                  isEditing.id !== data.questionId &&
                  !isFormValid()
                ) {
                  return false;
                } else if (isEditing && isEditing.id === data.questionId) {
                  return false;
                }

                setIsEditing({
                  id: data.questionId,
                  previousData: data,
                });
              }}
            >
              <div className="content-question">
                {isEditing.id === data.questionId ? (
                  <>
                    {themeQuestionEnabled && (
                      <ContentEditable
                        className="category-question"
                        onUpdate={(v) => updateQuestion(v, "title")}
                        placeholder="Thème sans titre"
                        setPlaceholderDefault={true}
                      >
                        {data.title}
                      </ContentEditable>
                    )}
                    <Input
                      value={data.question}
                      onChange={(v) => updateQuestion(v, "question")}
                      placeholder="Question sans titre"
                      setPlaceholderDefault={true}
                    />
                    <div className="select-question">
                      Type :
                      <Select
                        value={data.type}
                        setValue={(v) => updateQuestion(v, "type")}
                        placeholder="Choisissez le type de question"
                        options={displayTranslatedType()}
                      />
                    </div>
                    <div className="responses">
                      {data.props.options && data.props.options.length > 0 && (
                        <>
                          {data.props.options.map((response, i) => {
                            return (
                              <div className="answer" key={response.answerID}>
                                <div className="content-answer">
                                  {data.type === "Select" ? (
                                    <div className="number">{i + 1}</div>
                                  ) : (
                                    <IoIosRadioButtonOff />
                                  )}
                                  {helpers.getEncodedImg(
                                    response.answerName
                                  ) ? (
                                    <div className="option-img-edit">
                                      <img
                                        src={helpers.getEncodedImg(
                                          response.answerName
                                        )}
                                        alt="response"
                                        onClick={helpers.popupImg}
                                        className="img-option"
                                      />
                                      <div
                                        className="remove"
                                        onClick={() => {
                                          setIsModified(true);
                                          editAnswerName("", response.answerID);
                                        }}
                                      >
                                        <span aria-hidden="true">×</span>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {helpers.isOptionImageAllowed(
                                        data.type
                                      ) && (
                                        <div className="select-img">
                                          <button
                                            className="icon"
                                            onClick={() =>
                                              setIsModalOpen({
                                                questionId: data.questionId,
                                                answerID: response.answerID,
                                              })
                                            }
                                          >
                                            <IoIosImage />
                                          </button>
                                          <span className="or">ou</span>
                                        </div>
                                      )}
                                      <Input
                                        className="text"
                                        emoji={true}
                                        biggerOneEmojiContent={
                                          data.type !== "Select" ? true : false
                                        }
                                        value={response.answerName}
                                        placeholder="Option sans nom"
                                        setPlaceholderDefault={true}
                                        onChange={(v) =>
                                          editAnswerName(v, response.answerID)
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                                {data.props.options.length > 1 && (
                                  <button
                                    className="icon"
                                    onClick={() =>
                                      removeAnswer(response.answerID)
                                    }
                                  >
                                    <XIcon className="x-answer" />
                                  </button>
                                )}
                              </div>
                            );
                          })}
                          <div className="answer add" onClick={addAnswer}>
                            {data.type === "Select" ? (
                              <div className="number">
                                {data.props.options.length + 1}
                              </div>
                            ) : (
                              <IoIosRadioButtonOff />
                            )}
                            <div className="text">Ajouter une option</div>
                          </div>
                        </>
                      )}
                      {data.type === "Range" && (
                        <div className="range">
                          <div className="select-question">
                            De
                            <Select
                              value={data.props.min}
                              setValue={(v) => updateQuestionProp(v, "min")}
                              options={[0, 1]}
                              placeholder="Seuil minimum"
                            />
                          </div>
                          <div className="select-question">
                            À
                            <Select
                              value={data.props.max}
                              setValue={(v) => updateQuestionProp(v, "max")}
                              options={[5, 10, 20, 100]}
                              placeholder="Seuil maximum"
                            />
                          </div>
                          <div className="select-question">
                            Pallier
                            <Select
                              value={data.props.step}
                              setValue={(v) => updateQuestionProp(v, "step")}
                              options={getPalier}
                              placeholder="Palier"
                            />
                          </div>
                        </div>
                      )}
                      {data.props.multiple !== undefined && (
                        <div className="responses switch">
                          <label>
                            <span className="text-label">
                              Autoriser les réponses multiples
                            </span>
                            <Switch
                              onChange={(v) =>
                                updateQuestionProp(v, "multiple")
                              }
                              checked={
                                data.props.multiple === null
                                  ? false
                                  : data.props.multiple
                              }
                              className="react-switch"
                            />
                          </label>
                        </div>
                      )}
                      {data.props.displayAsGrid !== undefined && (
                        <div className="responses switch">
                          <label>
                            <span className="text-label">
                              Affichage sous forme horizontale
                            </span>
                            <Switch
                              onChange={(v) =>
                                updateQuestionProp(v, "displayAsGrid")
                              }
                              checked={
                                data.props.displayAsGrid === null
                                  ? false
                                  : data.props.displayAsGrid
                              }
                              className="react-switch"
                            />
                          </label>
                        </div>
                      )}
                      {data.open && (
                        <div>
                          <div className="answer-open">Zone de texte</div>
                        </div>
                      )}
                    </div>
                    <div className="row top-bar bottom">
                      <div className="left buttons">
                        {helpers.isQuestionCanBeRequired(data.type) && (
                          <div className="switch mandatory">
                            <label>
                              <span className="text-label">
                                Question obligatoire
                              </span>
                              <Switch
                                onChange={(v) => updateQuestion(v, "required")}
                                checked={
                                  data.required === null ? false : data.required
                                }
                                className="react-switch"
                              />
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="right buttons">
                        {isEditing && isEditing.previousData && (
                          <button
                            className="icon"
                            onClick={async (e) => {
                              e.stopPropagation();
                              const { isConfirmed } = await new swal({
                                title:
                                  "Voulez-vous annuler les modifications ?",
                                icon: "error",
                                showCancelButton: true,
                                confirmButtonText: "Oui",
                                cancelButtonText: "Non",
                              });
                              if (!isConfirmed) {
                                return false;
                              }

                              setData(
                                questions.map((question) =>
                                  question.questionId === isEditing.id
                                    ? isEditing.previousData
                                    : question
                                )
                              );
                              setIsEditing(false);
                            }}
                            title="Annuler les modifications"
                          >
                            <IoIosUndo />
                          </button>
                        )}
                        <button
                          className="icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeQuestion(data.questionId);
                          }}
                          title="Supprimer cette question"
                        >
                          <IoIosTrash />
                        </button>
                        <button
                          className="icon"
                          title="Valider les modifications"
                          onClick={(e) => {
                            if (!isFormValid()) {
                              return false;
                            }
                            e.stopPropagation();
                            setIsEditing(false);
                          }}
                        >
                          <IoIosCheckmarkCircleOutline />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {themeQuestionEnabled && (
                      <div className="category-question">{data.title}</div>
                    )}
                    <div className="description-question">
                      {data.question}
                      {data.required && <span className="required">*</span>}
                    </div>
                    <div className="type-question">
                      Type : <strong>{displayTranslatedType(data.type)}</strong>
                    </div>

                    <div className="responses">
                      {data.props.options &&
                        !data.props.displayAsGrid &&
                        data.props.options.map((response, i) => {
                          return (
                            <div className="answer" key={response.answerID}>
                              {data.type === "Select" ? (
                                <div className="number">{i + 1}</div>
                              ) : (
                                <IoIosRadioButtonOff />
                              )}
                              {helpers.getEncodedImg(response.answerName) ? (
                                <img
                                  src={helpers.getEncodedImg(
                                    response.answerName
                                  )}
                                  alt="response"
                                  className="img-option"
                                />
                              ) : data.type !== "Select" &&
                                helpers.isOnlyOneEmoji(response.answerName) ? (
                                <span className="bigger">
                                  {response.answerName}
                                </span>
                              ) : (
                                response.answerName
                              )}
                            </div>
                          );
                        })}
                      {data.props.options &&
                        data.type === "Tags" &&
                        data.props.displayAsGrid && (
                          <div className="tags">
                            {data.props.options.map((response) => {
                              return (
                                <div
                                  className={`tag ${
                                    helpers.isOnlyOneEmoji(response.answerName)
                                      ? " bigger"
                                      : ""
                                  }`}
                                  key={response.answerID}
                                >
                                  {helpers.getEncodedImg(
                                    response.answerName
                                  ) ? (
                                    <img
                                      src={helpers.getEncodedImg(
                                        response.answerName
                                      )}
                                      alt="response"
                                      className="img-option"
                                    />
                                  ) : (
                                    response.answerName
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      {!data.open &&
                        Object.keys(data.props)
                          .filter(
                            (o) =>
                              ![
                                "options",
                                "displayAsGrid",
                                "required",
                                "placeholder",
                                "",
                              ].includes(o)
                          )
                          .map((prop) => {
                            return (
                              <div className="row left" key={prop}>
                                {prop} :{" "}
                                <strong>
                                  {typeof data.props[prop] === "boolean"
                                    ? data.props[prop]
                                      ? "Activé"
                                      : "Désactivé"
                                    : data.props[prop]}
                                </strong>
                              </div>
                            );
                          })}
                      {data.open && (
                        <div>
                          <div className="answer-open">Zone de texte</div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Modal
              isModalOpen={isModalOpen}
              setModalOpen={setIsModalOpen}
              title="Uploader une image"
              content={generateModalContent}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

export default QuestionCreate;
