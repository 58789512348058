import { Link } from "react-router-dom";
import api from "../services/user.service";
import { useEffect, useState } from "react";
import Loading from "../components/Loading/Basic";
import Header from "../components/Layout/Header";
import NotFound from "./NotFound";
import { getRole } from "../components/Permissions/roles";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (isLoading !== true) {
      return false;
    }

    const fetchData = async () => {
      try {
        const response = await api.getUsers();
        if (response) {
          setData(
            response.data.map((item) => {
              return {
                id: item._id,
                name: `${item.account.firstname} ${item.account.lastname}`,
                email: item.email,
                created: item.account.created,
                role: item.account.role,
                verified: item.account?.verify.isVerified === true,
              };
            })
          );
          setIsLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          return setIsLoading(403);
        }
        console.log(error, "details");
        setIsLoading(500);
      }
    };
    fetchData();

    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  return isLoading === 401 || isLoading === 403 || isLoading === 500 ? (
    <NotFound error={isLoading} />
  ) : isLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <section className="home">
        <div className="container">
          <div className="emphase">
            <div className="name-emphase">Liste des utilisateurs</div>
          </div>
          <div className="questions">
            {data.length > 0 ? (
              <table className="rev-table">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Email</th>
                    {data[0].role && <th>Rôle</th>}
                    <th>Créé le</th>
                    <th>Verifié</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d) => {
                    return (
                      <tr key={d.id}>
                        <td>
                          <Link to={`../user/${d.id}`}>{d.name}</Link>
                        </td>
                        <td>
                          <a href={`mailto:${d.email}`}>{d.email}</a>
                        </td>
                        {d.role && <td>{getRole(d.role)}</td>}
                        <td>{new Date(d.created).toLocaleString()}</td>
                        <td>{d.verified ? "Oui" : "Non"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="empty">Aucun utilisateur pour le moment</div>
            )}
            <div className="row">
              <Link to="../user/new" className="submit">
                Créer un utilisateur
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Users;
