import SelectUI from "react-select";
import * as Hicons from "react-icons/hi";
import * as BSicons from "react-icons/bs";

const Select = ({
  value,
  error,
  setValue,
  options,
  placeholder,
  returnValueObject = false,
}) => {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      marginTop: 2,
    }),
    control: (provided, state) => ({
      ...provided,
      border: error ? "2px solid var(--color-red)" : provided.border,
    }),
  };

  options = options.map((option) => {
    return {
      value: option.answerID
        ? option.answerID
        : option.value
        ? option.value
        : option,
      label: option.answerName
        ? option.answerName
        : option.value
        ? option.label
        : option,
      icon:
        option.icon && Hicons[option.icon]
          ? Hicons[option.icon]()
          : option.icon && BSicons[option.icon]
          ? BSicons[option.icon]()
          : false,
    };
  });

  return (
    <div className={`select-input${error !== false ? " error bounce" : ""}`}>
      <SelectUI
        value={options.find((option) => option.value === value)}
        onChange={(v) =>
          setValue(
            typeof v.value !== "undefined" && !returnValueObject ? v.value : v
          )
        }
        placeholder={placeholder || ""}
        options={options}
        styles={customStyles}
        getOptionLabel={(e) =>
          e.icon ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              {e.icon}
              <span style={{ marginLeft: 15 }}>{e.label}</span>
            </div>
          ) : (
            e.label
          )
        }
      />
    </div>
  );
};

export default Select;
