import { useEffect, useState, useCallback } from "react";
import Helpers from "../../Helpers";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Items from "./Items";

const MultipleInput = ({
  value,
  setValue,
  placeholder,
  reorder = true,
  returnValueObject = false,
}) => {
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);

  /*
    List Of All Files:
    state: {
        name: value,
        name1: value1,
        name2: value2,
    }
  */

  useEffect(() => {
    if (alreadyLoaded) {
      return false;
    }

    if (value === "") {
      setValue([
        {
          name: Helpers.generateKey(5, 1),
          value: value,
        },
      ]);
    }
    setAlreadyLoaded(true);
  }, [setValue, value, alreadyLoaded]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      console.log([dragIndex, hoverIndex, value, value[dragIndex]]);
      console.log(value[dragIndex], dragIndex, value[hoverIndex], hoverIndex);

      const newValue = [...value];
      newValue[dragIndex] = newValue[hoverIndex];
      newValue[hoverIndex] = value[dragIndex];
      setValue(newValue);
    },
    [value, setValue]
  );

  return (
    <div className="inputs-text-list">
      <DndProvider backend={HTML5Backend}>
        {Array.isArray(value)
          ? value.map((input, index) => {
              return (
                <Items
                  input={input}
                  key={index}
                  index={index}
                  value={value}
                  setValue={setValue}
                  reorder={reorder}
                  moveCard={moveCard}
                  placeholder={placeholder}
                />
              );
            })
          : "Chargement"}
      </DndProvider>
    </div>
  );
};

export default MultipleInput;
