import api from "./axios.service";
import TokenService from "./token.service";

class AuthService {
  async login(email, password) {
    return await api.post("/user/login", {
      email,
      password,
    });
  }

  async resetPassword(email) {
    return await api.post("/user/reset-password", {
      email,
    });
  }

  async confirmResetPassword(email, token) {
    return await api.post("/user/confirm-reset-password", {
      email,
      secureToken: token,
    });
  }

  async logout() {
    TokenService.removeUser();
  }

  async register(email, firstname, lastname, role = false) {
    const data = {
      email,
      account: {
        firstname,
        lastname,
      },
    };
    if (role) {
      data.account.role = role;
    }
    return await api.post(`/${role ? "user" : "guest"}/register`, data);
  }

  async resendActivation(email) {
    return await api.post("/user/resend-activation", { email });
  }

  async confirmActivation(email, token) {
    return await api.post("/user/confirm-account", { email, token });
  }

  delay(t) {
    return new Promise((resolve) => setTimeout(resolve, t));
  }

  getCurrentUser() {
    return TokenService.getUser();
  }

  isConnected() {
    return this.getCurrentUser() ? true : false;
  }
}

export default new AuthService();
