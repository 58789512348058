import { Link } from "react-router-dom";
import api from "../services/forms.service";
import { useEffect, useState, useRef } from "react";
import Loading from "../components/Loading/Basic";
import Header from "../components/Layout/Header";
import NotFound from "./NotFound";
import { IsAuthorized } from "../components/Permissions/roles";
import { Watcher } from "../components/Permissions/Watcher";
import { Moderator } from "../components/Permissions/Moderator";
import {
  IoEllipsisVertical,
  IoPencil,
  IoTrashBinOutline,
} from "react-icons/io5";
import swal from "sweetalert2";

const AllForms = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const actions = useRef([]);
  const isModerator = IsAuthorized("moderator");
  const isAdmin = IsAuthorized("admin");

  useEffect(() => {
    if (isLoading !== true) {
      return false;
    }

    const fetchData = async () => {
      try {
        const response = await api.getAllForms();
        if (response) {
          setData(
            response.data.map((item) => {
              return {
                id: item._id,
                name: item.name,
                user: item.owner ? item.owner : "Unknow",
                lastUpdate: item.updatedAt,
              };
            })
          );
          setIsLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          return setIsLoading(403);
        }
        setIsLoading(500);
      }
    };
    fetchData();

    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isActionOpen !== false &&
        actions.current &&
        !actions.current[isActionOpen].contains(event.target)
      ) {
        setIsActionOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isActionOpen]);

  const deleteForm = async (id) => {
    setIsLoading(true);
    try {
      const response = await api.deleteForm(id);
      if (response.data && response.data.success) {
        setData(data.filter((f) => f.id !== id));
      }
    } catch (error) {
      console.log(error.response);
      return setIsLoading(500);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading === 401 || isLoading === 403 || isLoading === 500 ? (
    <NotFound error={isLoading} />
  ) : isLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <section className="home">
        <div className="container">
          <div className="emphase">
            <div className="name-emphase">Liste des formulaires</div>
          </div>
          <div className="questions">
            {data.length > 0 ? (
              <table className="rev-table">
                <thead>
                  <tr>
                    <th>Formulaire</th>
                    <th>Utilisateur</th>
                    <th>Dernière modification</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, i) => {
                    return (
                      <tr key={d.id}>
                        <td>
                          <Link to={`../form/${d.id}`} target="_blank">
                            {d.name}
                          </Link>
                        </td>
                        <td>
                          {d.user.account ? (
                            <>
                              <Moderator conditional={true}>
                                <a
                                  href={`../user/${d.user._id}`}
                                >{`${d.user.account.firstname} 
                                ${d.user.account.lastname}`}</a>
                              </Moderator>
                              <Watcher conditional={true} extended={false}>
                                {`${d.user.account.firstname} 
                                ${d.user.account.lastname}`}
                              </Watcher>
                            </>
                          ) : (
                            "Inconnu"
                          )}
                        </td>
                        <td>{new Date(d.lastUpdate).toLocaleString()}</td>
                        {isModerator || isAdmin ? (
                          <td>
                            <div className="menu">
                              <span
                                className="action"
                                onClick={() =>
                                  setIsActionOpen(
                                    isActionOpen !== i
                                      ? i
                                      : isActionOpen !== false
                                      ? false
                                      : i
                                  )
                                }
                              >
                                <IoEllipsisVertical size="1.4em" />
                              </span>

                              <div
                                className={`submenu animated${
                                  isActionOpen === i ? " show" : ""
                                }`}
                                ref={(el) => {
                                  actions.current[i] = el;
                                }}
                              >
                                <Link to={`/forms/edit/${d.id}`}>
                                  <IoPencil />
                                  Editer
                                </Link>
                                <Link
                                  to="/"
                                  onClick={async (e) => {
                                    e.preventDefault();

                                    const { isConfirmed } = await new swal({
                                      title:
                                        "Etes-vous sûr de vouloir supprimer ce formulaire ?",
                                      icon: "error",
                                      showCancelButton: true,
                                      confirmButtonText: "Oui",
                                      cancelButtonText: "Non",
                                    });
                                    if (!isConfirmed) {
                                      return false;
                                    }

                                    deleteForm(d.id);
                                  }}
                                >
                                  <IoTrashBinOutline />
                                  Supprimer
                                </Link>
                              </div>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="empty">Aucun formulaire pour le moment</div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default AllForms;
