import helpers from "../Helpers";
import { IoIosRadioButtonOff } from "react-icons/io";
import { AiFillCheckCircle } from "react-icons/ai";
import { useCallback } from "react";

const Tags = ({
  value,
  setValue,
  options,
  grid,
  error,
  multiple = false,
  returnValueObject = false,
}) => {
  const isSelected = useCallback(
    (tag) => {
      return (!multiple && value === tag.answerID) ||
        (multiple &&
          Array.isArray(value) &&
          value.find((v) => tag.answerID === v.answerID))
        ? true
        : false;
    },
    [value, multiple]
  );

  const changeValue = (tag, value) => {
    if (multiple) {
      if (
        Array.isArray(value) &&
        value.find((v) => tag.answerID === v.answerID)
      ) {
        return setValue(value.filter((v) => v.answerID !== tag.answerID));
      }
      if (value === false) {
        setValue([tag]);
      } else {
        setValue([...value, tag]);
      }
    } else {
      setValue(returnValueObject ? tag : tag.answerID);
    }
  };

  return (
    <div
      className={`tags${grid ? "" : " list"}${
        error !== false ? " error bounce" : ""
      }`}
      data-name="moment"
    >
      {options.map((tag) => {
        return (
          <div
            key={tag.answerID}
            className={`tag${isSelected(tag) ? " selected" : ""}${
              helpers.isOnlyOneEmoji(tag.answerName) ? " bigger" : ""
            }`}
            onClick={() => changeValue(tag, value)}
          >
            {!grid && isSelected(tag) ? (
              <AiFillCheckCircle />
            ) : (
              <IoIosRadioButtonOff />
            )}
            {helpers.getEncodedImg(tag.answerName) ? (
              <img
                src={helpers.getEncodedImg(tag.answerName)}
                alt="response"
                className="img-option"
              />
            ) : (
              tag.answerName
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Tags;
