import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

const Range = ({ value, setValue, min = 0, max = 10, step = 1 }) => {
  return (
    <div className="range">
      <InputRange
        minValue={min}
        maxValue={max}
        step={step}
        value={value || min}
        onChange={(v) => setValue(v)}
      />
    </div>
  );
};

export default Range;
