import api from "./axios.service";
import Helpers from "../components/Helpers";
import auth from "../services/auth.service";

class FormsService {
  async getMyForms() {
    return await api.get("forms/my");
  }

  async getAllForms() {
    return await api.get("forms/all");
  }

  async createForm(data = false) {
    return await api.post(
      "forms/create",
      data
        ? data
        : {
            name: "Formulaire sans titre",
            answerAccepted: true,
            questions: Helpers.newQuestionStructure(0),
          }
    );
  }

  async getForm(id) {
    return await api.get(`forms/edit/${id}`);
  }

  async getFormAsGuest(id) {
    return await api.get(`form/${id}`);
  }

  async deleteForm(id) {
    return await api.delete(`forms/delete/${id}`);
  }

  async updateForm(id, data) {
    return await api.put(`forms/update/${id}`, data);
  }

  async addImage(id, questionID, answerID, file) {
    const formData = new FormData();
    formData.append("image", file[0].file);
    formData.append("questionID", questionID);
    formData.append("responseID", answerID);

    return await api.post(`forms/addimage/${id}`, formData);
  }

  /**
   * Complete the survey
   * @param {number} id Form's ID
   * @param {object} data Questions ID with answers
   * @returns
   */
  async completeForm(id, responses, email) {
    return await api.post(`forms/complete/${id}`, {
      responses,
      mailRespondant: email,
      isConnected: auth.isConnected(),
    });
  }
}

export default new FormsService();
