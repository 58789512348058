import "emoji-mart/css/emoji-mart.css";
import "./input-emoji-picker.css";
import { Picker } from "emoji-mart";
import { useEffect, useRef, useState } from "react";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import Helpers from "../Helpers";

const Input = ({
  type,
  value,
  placeholder,
  onChange,
  emoji = false,
  biggerOneEmojiContent = false,
  setPlaceholderDefault = false,
}) => {
  const [isEmojiPickerActive, setIsEmojiPickerActive] = useState(false);
  const [eventCache, setEventCache] = useState(false);
  const timerNextEvent = 350;
  const input = useRef();
  const picker = useRef();

  if (typeof value === "undefined" || !onChange) {
    throw new Error("Input required value & onChange");
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (eventCache) {
        setTimeout(() => {
          handleBlur(eventCache);
        }, timerNextEvent);
        setEventCache(false);
      }

      if (picker.current && !picker.current.contains(event.target)) {
        setIsEmojiPickerActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, [isEmojiPickerActive]);

  useEffect(() => {
    if (eventCache) {
      setTimeout(() => {
        if (!picker.current) {
          return false;
        }

        if (
          !picker.current.className ||
          !picker.current.className.match(/active/)
        ) {
          handleBlur(eventCache);
        }
      }, timerNextEvent);
      setEventCache(false);
    }
    // eslint-disable-next-line
  }, [eventCache]);

  const handleBlur = (e) => {
    if (
      setPlaceholderDefault &&
      !Helpers.hasEmoji(e.target.value) &&
      (e.target.value === "" || !e.target.value.match(/\w/))
    ) {
      e.target.value = placeholder;
      onChange(e.target.value);
    }
  };

  return emoji ? (
    <div className={`input-emoji${isEmojiPickerActive ? " show" : ""}`}>
      <div className="input-container">
        <input
          type={type || "text"}
          value={value}
          ref={input}
          className={
            biggerOneEmojiContent && Helpers.isOnlyOneEmoji(value)
              ? "one-emoji"
              : ""
          }
          placeholder={placeholder}
          onBlur={setEventCache}
          onChange={(e) => onChange(e.target.value)}
        />
        <button
          className="icon"
          onClick={() =>
            setIsEmojiPickerActive(isEmojiPickerActive ? false : true)
          }
        >
          <HiOutlineEmojiHappy />
        </button>
      </div>
      <div
        className={`pickeremoji${isEmojiPickerActive ? " active" : ""}`}
        ref={picker}
      >
        <Picker
          onSelect={(e) => {
            onChange(input.current.value + e.native);
            setIsEmojiPickerActive(false);
          }}
        />
      </div>
    </div>
  ) : (
    <input
      type={type || "text"}
      value={value}
      placeholder={placeholder}
      onBlur={handleBlur}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default Input;
