import { Link, useHistory } from "react-router-dom";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import { GrDocumentText } from "react-icons/gr";
import api from "../services/forms.service";
import { useEffect, useState } from "react";
import Loading from "../components/Loading/Basic";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import NotFound from "./NotFound";
import Login from "./Login";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingNewForm, setIsCreatingNewForm] = useState(false);
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (isLoading !== true) {
      return false;
    }

    const fetchData = async () => {
      try {
        const response = await api.getMyForms();
        if (response) {
          setData(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          return setIsLoading(403);
        }
        setIsLoading(500);
      }
    };
    fetchData();

    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  const createForm = async () => {
    setIsCreatingNewForm(true);
    try {
      const response = await api.createForm();
      if (response.data) {
        history.push("/forms/edit/" + response.data._id);
      }
      if (response) {
        setData(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      alert("An error has occured");
    } finally {
      setIsCreatingNewForm(true);
    }
  };

  return isLoading === 401 || isLoading === 403 ? (
    <Login />
  ) : isLoading === 500 ? (
    <NotFound error={isLoading} />
  ) : isLoading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <section className="home">
        <div className="container">
          <div className="emphase">
            <div className="name-emphase">Mes formulaires</div>
          </div>
          <div className="alert full info alert-sep">
            <i className="icon"></i>
            <div className="content">
              <div>
                Revine Forms a été développé par{" "}
                <a href="https://www.revine.fr">Revine</a> et se présente comme
                alternative pour créer des formulaires tout en respectant votre
                vie privée.
              </div>
              <div>
                La plateforme est actuellement en version bêta, certains
                comportements sont susceptibles de ne pas fonctionner
                correctement, hésitez pas à{" "}
                <a href="mailto:feedback@forms.revine.fr">
                  nous signaler d'éventuels bugs ou ides d'améliorations
                </a>
                .
              </div>
            </div>
          </div>
          <div className="questions">
            {data.length > 0 ? (
              <>
                <div className="list-title">
                  <div className="name">Nom</div>
                  <div className="date">Dernière modification</div>
                </div>
                <ul>
                  {data.map((form) => {
                    return (
                      <li key={form._id}>
                        <Link
                          to={`/forms/edit/${form._id}`}
                          className="form-list"
                        >
                          <div className="name-list">
                            <IconContext.Provider
                              value={{ className: "stk-white" }}
                            >
                              <div>
                                <GrDocumentText />
                              </div>
                            </IconContext.Provider>
                            <div className="text-list">{form.name}</div>
                          </div>
                          <div className="date-list">
                            {new Date(form.updatedAt).toLocaleDateString()}
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <div className="empty">Aucun formulaire pour le moment</div>
            )}
            <div className="row">
              <button
                className="submit load"
                disabled={isCreatingNewForm}
                onClick={createForm}
              >
                <IoIosCheckmarkCircleOutline />
                Créer un nouveau formulaire
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
