import "../Basic/Loading.css";
import ContentLoader from "react-content-loader";

const LoadingContentLoader = (props) => {
  return (
    <div className="contentloader">
      <div className="logo-origin"></div>
      <ContentLoader
        speed={2}
        width={400}
        height={150}
        viewBox="0 0 400 150"
        backgroundColor="#BDBDBD"
        foregroundColor="#3f7cc4"
        {...props}
      >
        <circle cx="10" cy="20" r="8" />
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="50" r="8" />
        <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="80" r="8" />
        <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="110" r="8" />
        <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
      </ContentLoader>
    </div>
  );
};

export default LoadingContentLoader;
