import Helpers from "../components/Helpers";

class TokenService {
  constructor() {
    this.nameItem = "ur";
    this.nameFlash = "rf";
  }

  save(string) {
    return btoa(
      encodeURIComponent(string).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      )
    );
  }

  read(string) {
    if (!string) {
      return string;
    }
    return decodeURIComponent(
      atob(string)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  getData(auth = this.nameItem) {
    if (typeof auth !== "string" && auth !== "") {
      return false;
    }

    if (!localStorage.getItem(auth)) {
      return false;
    }

    try {
      const data = JSON.parse(this.read(localStorage.getItem(auth)));
      data.email.match(/@/);
      return data;
    } catch (error) {
      this.removeUser();
      return false;
    }
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem(this.nameItem));
    user.token = token;
    this.setUser(user);
  }

  getUser(field = false) {
    const data = this.getData();
    return field && data[field] ? data[field] : field ? false : data;
  }

  setUser(data) {
    localStorage.setItem(
      this.nameItem,
      this.save(
        JSON.stringify({
          id: data._id,
          account: data.account,
          email: data.email,
          token: data.token,
          refreshToken: data.refreshToken,
          tokenExpires: data.expires,
        })
      )
    );
  }

  getToken() {
    return this.getUser("token");
  }

  removeUser() {
    localStorage.removeItem(this.nameItem);
  }

  getLoginFlashMsg() {
    const instance = this.service ? this.service : this;
    let data = sessionStorage.getItem(instance.nameFlash);
    if (!data) {
      return false;
    }
    data = instance.read(data);
    sessionStorage.removeItem(instance.nameFlash);
    return Helpers.isJSON(data) ? JSON.parse(data) : data;
  }

  setLoginFlashMsg(data) {
    const instance = this.service ? this.service : this;
    data = typeof data === "object" ? JSON.stringify(data) : data;
    sessionStorage.setItem(instance.nameFlash, instance.save(data));
  }
}

export default new TokenService();
